<template>
  <section class="">
    <div class="svg-right-2 z-0"></div>
    <div class="bg-gray-100 border rounded-lg border-2">
      <div
        class="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between"
      >
        <h2
          class="text-3xl leading-9 font-extrabold tracking text-gray-800 sm:text-4xl sm:leading-10"
        >
          Wish to be a Partner with Repair Phone?
          <br />
          <span class="text-3xl accent-text-color">Send us an email right away</span>
        </h2>
        <div class="mt-8 flex lg:flex-shrink-0 lg:mt-0 z-20">
          <div class="inline-flex rounded-md shadow">
            <a
              href="mailto:partner@repairphone.in"
              class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white focus:outline-none focus:shadow-outline transition duration-150 ease-in-out font-semibold bg-gray-800"
            >
              Email Now
              <svg
                class="ml-1 h-5 w-5"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path
                  class="heroicon-ui"
                  d="M4 4h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2zm16 3.38V6H4v1.38l8 4 8-4zm0 2.24l-7.55 3.77a1 1 0 0 1-.9 0L4 9.62V18h16V9.62z"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Section5"
};
</script>

<style>
</style>
