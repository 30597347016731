const state = {
  loginModalVisibility: false,
};
const getters = {
  isLoginModalVisible: (state) => {
    return state.loginModalVisibility;
  },
};
const actions = {
  toggleLoginModal({ commit }) {
    commit("toggleLoginModalState");
  },
};
const mutations = {
  toggleLoginModalState: (state) => {
    state.loginModalVisibility = !state.loginModalVisibility;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
