var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-5 lg:mt-20 px-5"},[_c('h2',{staticClass:"font-bold text-gray-800 text-2xl lg:text-3xl leading-none"},[_vm._v(" Select Your Device Brand ")]),_c('div',{staticClass:"relative w-full mt-5 lg:w-3/5"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchTerm),expression:"searchTerm"}],staticClass:"transition duration-200 bg-background-form border-gray-500 rounded px-4 pl-10 py-3 outline-none focus:border-orange-400 w-full border-2",attrs:{"type":"text","placeholder":"Search for your device's brand"},domProps:{"value":(_vm.searchTerm)},on:{"input":function($event){if($event.target.composing)return;_vm.searchTerm=$event.target.value}}}),_c('div',{staticClass:"absolute top-0 ml-3",staticStyle:{"top":"15px"}},[_c('svg',{staticClass:"text-gray-500 h-5 w-5",attrs:{"fill":"currentColor","viewBox":"0 0 24 24","width":"24","height":"24"}},[_c('path',{staticClass:"heroicon-ui",attrs:{"d":"M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"}})])]),_c('div',{staticClass:"absolute right-0 text-2xl mr-3 cursor-pointer text-gray-600 hover:text-gray-800",staticStyle:{"top":"50%","transform":"translate(0, -50%)"}},[_vm._v(" × ")])]),_c('transition',{attrs:{"name":"fade"}},[_c('div',{staticClass:"grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 md:gap-6 mt-5 lg:mt-10"},_vm._l((_vm.filteredPosts),function(brands){return _c('div',{key:brands._id,staticClass:"bg-white border-dashed border-2 hover:border-red-500 rounded-md p-2 lg:py-4 lg:px-3 flex transition-all duration-300 transform cursor-pointer items-center",on:{"click":function($event){_vm.incrementSteps(1);
          _vm.setSelectedBrand(brands.name);
          _vm.setSelectedBrandId(brands._id);}}},[_c('div',{staticClass:"ml-0 lg:ml-0 md:m-0 inline-block"},[_c('picture',[_c('source',{attrs:{"srcset":'https://assets.cashmen.in/res/brandimages/v2/' +
                brands._id +
                '.webp',"type":"image/webp"}}),_c('source',{attrs:{"srcset":'https://assets.cashmen.in/res/brandimages/v2/' +
                brands._id +
                '.png',"type":"image/jpeg"}}),_c('img',{staticClass:"h-10 md:h-16",attrs:{"src":'https://assets.cashmen.in/res/brandimages/v2/' +
                brands._id +
                '.png'}})])]),_c('div',{staticClass:"ml-5"},[_c('h1',{staticClass:"text-gray-700 font-bold text-lg lg:text-xl xl:text-2xl"},[_vm._v(" "+_vm._s(brands.name)+" ")]),(brands.moto.length <= 20)?_c('p',{staticClass:"text-gray-600 relative hidden lg:block"},[_c('svg',{staticClass:"absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-5 w-5 text-gray-800 opacity-25",attrs:{"fill":"currentColor","viewBox":"0 0 32 32"}},[_c('path',{attrs:{"d":"M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z"}})]),_vm._v(" "+_vm._s(brands.moto.slice(0, 20))+" ")]):_vm._e(),(brands.moto.length > 20)?_c('p',{staticClass:"text-gray-600 relative hidden lg:block"},[_c('svg',{staticClass:"absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-5 w-5 text-gray-800 opacity-25",attrs:{"fill":"currentColor","viewBox":"0 0 32 32"}},[_c('path',{attrs:{"d":"M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z"}})]),_vm._v(" "+_vm._s(brands.moto.slice(0, 20))+"... ")]):_vm._e()])])}),0)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }