<template>
  <div class="my-20 lg:mb-40 text-gray-700 body-font relative px-5">
    <div class="text-center">
      <p
        class="text-base text-yellow-400 leading-6 font-semibold tracking-wide uppercase"
      >
        FEATURES
      </p>
      <h2
        class="font-bold text-gray-800 text-3xl md:text-4xl leading-none my-2"
      >
        Major issues that we handle
      </h2>
      <p class="lg:text-xl text-gray-700 max-w-4xl mx-auto">
        Here is what you get when you choose Repair Phone for your and your
        family's Mobile / Tablet repair
      </p>
    </div>
    <div class="mt-5 pt-8 lg:pt-16">
      <div class="-my-2 lg:flex lg:pb-20">
        <a
          class="block lg:mx-2 p-6 lg:w-1/3 bg-white border-4 border-gray-300 mb-20 lg:mb-0 shadow-xl rounded-lg transform transition duration-150 ease-in-out hover:scale-95"
          ><div
            class="p-0 block bg-gray-800 absolute rounded-full border-4 border-gray-500 p-4 -mt-16"
          >
            <svg
              class="h-8 w-8 text-gray-400"
              fill="currentcolor"
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path
                d="M13.5.67s.74 2.65.74 4.8c0 2.06-1.35 3.73-3.41 3.73-2.07 0-3.63-1.67-3.63-3.73l.03-.36C5.21 7.51 4 10.62 4 14c0 4.42 3.58 8 8 8s8-3.58 8-8C20 8.61 17.41 3.8 13.5.67zM11.71 19c-1.78 0-3.22-1.4-3.22-3.14 0-1.62 1.05-2.76 2.81-3.12 1.77-.36 3.6-1.21 4.62-2.58.39 1.29.59 2.65.59 4.04 0 2.65-2.15 4.8-4.8 4.8z"
              />
            </svg>
          </div>
          <h3 class="mt-4 text-xl text-gray-800 font-bold">
            Device Overheating
          </h3></a
        >
        <a
          class="block lg:mx-2 p-6 lg:w-1/3 bg-white border-4 border-gray-300 mb-20 lg:mb-0 shadow-xl rounded-lg transform transition duration-150 ease-in-out hover:scale-95"
          ><div
            class="p-0 block bg-gray-800 absolute rounded-full border-4 border-gray-500 p-4 -mt-16"
          >
            <svg
              class="h-8 w-8 text-gray-400"
              fill="currentcolor"
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path
                d="M22 21c-1.11 0-1.73-.37-2.18-.64-.37-.22-.6-.36-1.15-.36-.56 0-.78.13-1.15.36-.46.27-1.07.64-2.18.64s-1.73-.37-2.18-.64c-.37-.22-.6-.36-1.15-.36-.56 0-.78.13-1.15.36-.46.27-1.08.64-2.19.64-1.11 0-1.73-.37-2.18-.64-.37-.23-.6-.36-1.15-.36s-.78.13-1.15.36c-.46.27-1.08.64-2.19.64v-2c.56 0 .78-.13 1.15-.36.46-.27 1.08-.64 2.19-.64s1.73.37 2.18.64c.37.23.59.36 1.15.36.56 0 .78-.13 1.15-.36.46-.27 1.08-.64 2.19-.64 1.11 0 1.73.37 2.18.64.37.22.6.36 1.15.36s.78-.13 1.15-.36c.45-.27 1.07-.64 2.18-.64s1.73.37 2.18.64c.37.23.59.36 1.15.36v2zm0-4.5c-1.11 0-1.73-.37-2.18-.64-.37-.22-.6-.36-1.15-.36-.56 0-.78.13-1.15.36-.45.27-1.07.64-2.18.64s-1.73-.37-2.18-.64c-.37-.22-.6-.36-1.15-.36-.56 0-.78.13-1.15.36-.45.27-1.07.64-2.18.64s-1.73-.37-2.18-.64c-.37-.22-.6-.36-1.15-.36s-.78.13-1.15.36c-.47.27-1.09.64-2.2.64v-2c.56 0 .78-.13 1.15-.36.45-.27 1.07-.64 2.18-.64s1.73.37 2.18.64c.37.22.6.36 1.15.36.56 0 .78-.13 1.15-.36.45-.27 1.07-.64 2.18-.64s1.73.37 2.18.64c.37.22.6.36 1.15.36s.78-.13 1.15-.36c.45-.27 1.07-.64 2.18-.64s1.73.37 2.18.64c.37.22.6.36 1.15.36v2zM8.67 12c.56 0 .78-.13 1.15-.36.46-.27 1.08-.64 2.19-.64 1.11 0 1.73.37 2.18.64.37.22.6.36 1.15.36s.78-.13 1.15-.36c.12-.07.26-.15.41-.23L10.48 5C8.93 3.45 7.5 2.99 5 3v2.5c1.82-.01 2.89.39 4 1.5l1 1-3.25 3.25c.31.12.56.27.77.39.37.23.59.36 1.15.36z"
              />
              <circle cx="16.5" cy="5.5" r="2.5" />
            </svg>
          </div>
          <h3 class="mt-4 text-xl text-gray-800 font-bold">Water Damage</h3>
        </a>
        <a
          class="block lg:mx-2 p-6 lg:w-1/3 bg-white border-4 border-gray-300 mb-20 lg:mb-0 shadow-xl rounded-lg transform transition duration-150 ease-in-out hover:scale-95"
          ><div
            class="p-0 block bg-gray-800 absolute rounded-full border-4 border-gray-500 p-4 -mt-16"
          >
            <svg
              class="h-8 w-8 text-gray-400"
              fill="currentcolor"
              xmlns="http://www.w3.org/2000/svg"
              enable-background="new 0 0 24 24"
              height="24"
              viewBox="0 0 24 24"
              width="24"
            >
              <g><path d="M0,0h24v24H0V0z" fill="none" /></g>
              <g>
                <g>
                  <path
                    d="M22,13h-9v9H2L22,2V13z M21,15.41L19.59,14l-2.09,2.09L15.41,14L14,15.41l2.09,2.09L14,19.59L15.41,21l2.09-2.08L19.59,21 L21,19.59l-2.08-2.09L21,15.41z"
                  />
                </g>
              </g>
            </svg>
          </div>
          <h3 class="mt-4 text-xl text-gray-800 font-bold">Signal Failure (Network)</h3>
        </a>
      </div>
      <div class="lg:flex lg:pb-10">
        <a
          class="block lg:mx-2 p-6 lg:w-1/3 bg-white border-4 border-gray-300 mb-20 lg:mb-0 shadow-xl rounded-lg transform transition duration-150 ease-in-out hover:scale-95"
          ><div
            class="p-0 block bg-gray-800 absolute rounded-full border-4 border-gray-500 p-4 -mt-16"
          >
            <svg
              class="h-8 w-8 text-gray-400"
              fill="currentcolor"
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path
                d="M18 1.01L8 1c-1.1 0-2 .9-2 2v3h2V5h10v14H8v-1H6v3c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V3c0-1.1-.9-1.99-2-1.99zM10 15h2V8H5v2h3.59L3 15.59 4.41 17 10 11.41z"
              />
            </svg>
          </div>
          <h3 class="mt-4 text-xl text-gray-800 font-bold">Screen Repair (LCD)</h3>
        </a>
        <a
          class="block lg:mx-2 p-6 lg:w-1/3 bg-white border-4 border-gray-300 mb-20 lg:mb-0 shadow-xl rounded-lg transform transition duration-150 ease-in-out hover:scale-95"
          ><div
            class="p-0 block bg-gray-800 absolute rounded-full border-4 border-gray-500 p-4 -mt-16"
          >
            <svg
              class="h-8 w-8 text-gray-400"
              fill="currentcolor"
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path
                d="M15 9H9v6h6V9zm-2 4h-2v-2h2v2zm8-2V9h-2V7c0-1.1-.9-2-2-2h-2V3h-2v2h-2V3H9v2H7c-1.1 0-2 .9-2 2v2H3v2h2v2H3v2h2v2c0 1.1.9 2 2 2h2v2h2v-2h2v2h2v-2h2c1.1 0 2-.9 2-2v-2h2v-2h-2v-2h2zm-4 6H7V7h10v10z"
              />
            </svg>
          </div>
          <h3 class="mt-4 text-xl text-gray-800 font-bold">Motherboard Issues</h3>
        </a>
        <a
          class="block lg:mx-2 p-6 lg:w-1/3 bg-white border-4 border-gray-300 mb-20 lg:mb-0 shadow-xl rounded-lg transform transition duration-150 ease-in-out hover:scale-95"
          ><div
            class="p-0 block bg-gray-800 absolute rounded-full border-4 border-gray-500 p-4 -mt-16"
          >
            <svg
              class="h-8 w-8 text-gray-400"
              fill="currentcolor"
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path
                d="M15.67 4H14V2h-4v2H8.33C7.6 4 7 4.6 7 5.33v15.33C7 21.4 7.6 22 8.33 22h7.33c.74 0 1.34-.6 1.34-1.33V5.33C17 4.6 16.4 4 15.67 4zM13 18h-2v-2h2v2zm0-4h-2V9h2v5z"
              />
            </svg>
          </div>
          <h3 class="mt-4 text-xl text-gray-800 font-bold">Battery Problem</h3>
        </a>
      </div>
    </div>

    <div class="text-center">
      <h3 class="font-bold text-gray-800 text-xl md:text-2xl leading-none">
        And a lot more ...
      </h3>
    </div>
  </div>
</template>

<script>
export default {
  name: "Section6",
  data() {
    return {};
  },
};
</script>

<style>
</style>
