<template>
  <div class="home">
    <Header />
    <main class="custom-container">
      <Section1 />
      <Section6 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
    </main>
  </div>
</template>

<script>
import Header from "../components/Home/Header";
import Section1 from "../components/Home/Section-1";
import Section2 from "../components/Home/Section-2";
import Section3 from "../components/Home/Section-3";
import Section4 from "../components/Home/Section-4";
import Section5 from "../components/Home/Section-5";
import Section6 from "../components/Home/Section-6";

// Vuex
import { mapActions } from "vuex";

export default {
  name: "Home",
  components: {
    Header,
    Section1,
    Section2,
    Section3,
    Section4,
    Section5,
    Section6
  },
  methods: {
    ...mapActions(["resetSteps"])
  },
    metaInfo() {
    return {
      title:
        "Repair Phone - Repair your Phone or Tablet Instantly",
      meta: [
        {
          name:
            "Repair Phone - Repair your Phone or Tablet Instantly",
          content:
            "Repair Phone - Repair your Phone or Tablet Instantly",
        },
      ],
    };
  },
  created() {
    this.resetSteps();
  }
};
</script>
