/* eslint-disable */
export default {
  data() {
    return {
      errors: {
        firstName: null,
        lastName: null,
        number: null,
        email: null,
        address: null,
        pincode: null,
        dateSlot: null,
      },
    };
  },
  methods: {
    isValidPinCode() {
      this.correctPincode = false;
      this.errors.pincode = `Please enter correct Pincode!`;

      if (this.pincode.length === 6) {
        this.areaList.forEach((state) => {
          state.cities.forEach((city) => {
              city.pins.forEach((pin) => {
                if (pin.isActive) {
                  if (pin.pin == this.pincode) {
                    this.city =
                      city._id.charAt(0).toUpperCase() + city._id.slice(1);
                    this.state =
                      state._id.charAt(0).toUpperCase() + state._id.slice(1);

                    this.correctPincode = true;
                    this.errors.pincode = null;
                  }
                }
              });
          });
        });
      }
    },

    validate() {
      // First Name Validation
      if (this.firstName === null) {
        this.errors.firstName = `The first name field cannot be empty!`;
      } else if (this.firstName.length < 2) {
        this.errors.firstName = `Please enter your correct first name!`;
      } else {
        this.errors.firstName = null;
      }

      // Last Name Validation
      if (this.lastName === null) {
        this.errors.lastName = `The first name field cannot be empty!`;
      } else if (this.lastName.length < 2) {
        this.errors.lastName = `Please enter your correct first name!`;
      } else {
        this.errors.lastName = null;
      }

      // Number Validation
      if (this.phoneNumber === null) {
        this.errors.number = `The number field cannot be empty!`;
      } else if (this.phoneNumber.length != 10) {
        this.errors.number = `Please enter your correct 10 digit phone number!`;
      } else {
        this.errors.number = null;
      }

      // Email Validation
      var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      if (this.emailAddress === null) {
        this.errors.email = "The email field cannot be empty!";
      } else if (reg.test(this.emailAddress) == false) {
        this.errors.email = "Please enter correct email address";
      } else {
        this.errors.email = null;
      }

      // Address Validation
      if (this.address === null) {
        this.errors.address = `The address field cannot be empty!`;
      } else if (this.address.length < 5) {
        this.errors.address = `Please enter your correct address!`;
      } else {
        this.errors.address = null;
      }

      // Pincode Validation
      if (!this.correctPincode === true) {
        this.errors.pincode = `Please enter correct Pincode!`;
      }

      // Address Validation
      if (this.dateSlot === null) {
        this.errors.dateSlot = `The appointment slot field cannot be empty!`;
      } else {
        this.errors.dateSlot = null;
      }

      //   Proceed further if all cool
      if (
        this.errors.firstName === null &&
        this.errors.lastName === null &&
        this.errors.number === null &&
        this.errors.email === null &&
        this.errors.address === null &&
        this.errors.dateSlot === null &&
        this.errors.pincode === null
      ) {
        return true;
      } else {
        return false;
      }
    },

    checkPinCode() {
      this.isValidPinCode();
      
      // if (this.allPinCodes.includes(Number(this.pincode))) {
      //   this.correctPincode = true;
      //   this.errors.pincode = null;
      // } else {
      //   this.correctPincode = false;
      //   this.errors.pincode = `Please enter correct Pincode!`;
      // }
    },
  },
};
/* eslint-disable */
