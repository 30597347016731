import axios from 'axios';

const state = {
	// NOTE: CURRENTLY All cashmen active pincodes are
	pincodes: [],
	areaList: [],
};
const getters = {
	allPinCodes: (state) => {
		return state.pincodes;
	},
	areaList: (state) => {
		return state.areaList;
	},
};
const actions = {
	async fetchPinCodes({ commit, rootState }) {
		const response = await axios.get(
			`${rootState.serverURL}/apiV2/pincodes`
		);

		commit('setPinCodes', response.data);
	},
};
const mutations = {
	setPinCodes: (state, pincodes) => {
		state.areaList = pincodes;
		pincodes.forEach((state) => {
			if (state.isActive) {
        state.pincodes = []
				state.cities.forEach((city) => {
					if (city.isActive) {
						city.pins.forEach((element) => {
							if (element.isActive) {
								state.pincodes.push(element.pin);
							}
						});
					}
				});
			}
		});
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
