<template>
  <div>
    <nav
      class="custom-container flex items-center z-20 bg-white relative py-8 lg:py-10 px-5"
    >
      <router-link to="/">
        <img src="../assets/images/logo.png" alt="Repair Phone Logo" />
      </router-link>

      <button
        id="navbar-button"
        @click="toggleNavbar"
        class="border-none outline-none bg-transparent ml-auto cursor-pointer"
      >
        <span class="bg-gray-800"></span>
        <span class="bg-gray-800"></span>
        <span class="bg-gray-800"></span>
      </button>
      <ul class="ml-auto hidden lg:flex items-center">
        <li class="inline-block">
          <router-link
            class="text-sm font-bold uppercase no-underline text-gray-700 ml-10 tracking-widest hover:text-yellow-400 transition"
            to="/about-us"
            >About Us</router-link
          >
        </li>
        <li class="inline-block">
          <a
            class="text-sm font-bold uppercase no-underline text-gray-700 ml-10 tracking-widest hover:text-yellow-400 transition"
            href="https://cashmen.in/"
            target="_blank"
            >Sell Your Device?</a
          >
        </li>
        <li class="inline-block">
          <a
            class="flex text-sm font-bold uppercase no-underline text-gray-700 ml-10 tracking-widest hover:text-yellow-400 transition border-2 p-2 rounded-md border-yellow-400 cursor-pointer"
            @click="selectCity"
            ><span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                enable-background="new 0 0 24 24"
                version="1"
                viewBox="0 0 24 24"
                class="w-5 h-5 mr-2"
              >
                <path
                  d="M18.5,10.2c0,2.57-2.1,5.79-6.16,9.51L12,20.01l-0.34-0.31C7.6,15.99,5.5,12.77,5.5,10.2 c0-3.84,2.82-6.7,6.5-6.7S18.5,6.35,18.5,10.2z"
                  fill-opacity=".3"
                />
                <path
                  d="M12,2c4.2,0,8,3.22,8,8.2c0,3.32-2.67,7.25-8,11.8c-5.33-4.55-8-8.48-8-11.8C4,5.22,7.8,2,12,2z M18,10.2 C18,6.57,15.35,4,12,4s-6,2.57-6,6.2c0,2.34,1.95,5.44,6,9.14C16.05,15.64,18,12.54,18,10.2z M12,12c-1.1,0-2-0.9-2-2s0.9-2,2-2 s2,0.9,2,2S13.1,12,12,12z"
                />
                <path d="M0,0h24v24H0V0z" fill="none" />
              </svg>
            </span>
            {{ city }}</a
          >
        </li>
      </ul>
    </nav>
    <ul
      class="responsive-nav bg-white z-10 w-full absolute"
      :class="navbarVisible ? 'responsive-nav-active' : ''"
    >
      <li>
        <router-link
          class="text-sm font-bold uppercase no-underline text-gray-700 my-10 text-center block"
          to="/about-us"
          >About us</router-link
        >
      </li>
      <li>
        <a
          class="text-sm font-bold uppercase no-underline text-gray-700 my-10 text-center block"
          href="https://cashmen.in/"
          target="_blank"
          >Sell Your Device?</a
        >
      </li>
      <li>
        <a
          class="flex justify-center text-sm font-bold uppercase no-underline text-gray-700 my-10 text-center block"
          @click="selectCity"
          >
          <svg
                xmlns="http://www.w3.org/2000/svg"
                enable-background="new 0 0 24 24"
                version="1"
                viewBox="0 0 24 24"
                class="w-5 h-5 mr-2"
              >
                <path
                  d="M18.5,10.2c0,2.57-2.1,5.79-6.16,9.51L12,20.01l-0.34-0.31C7.6,15.99,5.5,12.77,5.5,10.2 c0-3.84,2.82-6.7,6.5-6.7S18.5,6.35,18.5,10.2z"
                  fill-opacity=".3"
                />
                <path
                  d="M12,2c4.2,0,8,3.22,8,8.2c0,3.32-2.67,7.25-8,11.8c-5.33-4.55-8-8.48-8-11.8C4,5.22,7.8,2,12,2z M18,10.2 C18,6.57,15.35,4,12,4s-6,2.57-6,6.2c0,2.34,1.95,5.44,6,9.14C16.05,15.64,18,12.54,18,10.2z M12,12c-1.1,0-2-0.9-2-2s0.9-2,2-2 s2,0.9,2,2S13.1,12,12,12z"
                />
                <path d="M0,0h24v24H0V0z" fill="none" />
              </svg>
          {{ city }}</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      navbarVisible: false,
      REPAIR_CITY_KEY: "repair-city-key",
      city: null,
    };
  },
  methods: {
    toggleNavbar() {
      this.navbarVisible = !this.navbarVisible;
    },
    selectCity(event) {
      this.$emit("selectcity");
    },
    checkCity() {
      setTimeout(() => {
        this.city = localStorage.getItem(this.REPAIR_CITY_KEY);
      }, 500);
    },
  },
  created() {
    this.city = localStorage.getItem(this.REPAIR_CITY_KEY);
  },
};
</script>

<style>
</style>
