<template>
  <div class="custom-container px-5 text-center mt-10">
    <picture class="mx-auto text-center misc-header-img">
      <source srcset="../assets/images/notfound.webp" type="image/webp" />
      <source srcset="../assets/images/notfound.png" type="image/jpeg" />
      <img
        class="mx-auto text-center misc-header-img"
        src="../assets/images/notfound.png"
        alt="Page not found image"
      />
    </picture>
    <h1 class="text-2xl mt-10 lg:text-3xl xl:text-4xl font-bold text-gray-800">
      Page Not Found
    </h1>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  metaInfo() {
    return {
      title: "Repair Phone - 404 Not Found",
      meta: [
        {
          name: "Repair Phone - 404 Not Found",
          content: "The page you were looking for is not found",
        },
      ],
    };
  },
};
</script>

<style>
</style>
