<template>
  <div class="absolute snackbar w-full">
    <div class="snackbar-inner shadow-lg bg-gray-800">
      <div class="custom-container">
        <div class="flex items-center">
          <img
            class="snackbar-logo"
            src="../assets/images/logo-white.png"
            alt=""
          />
          <span class="text-gray-400 ml-auto">
            <svg
              fill="currentcolor"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 512 512"
              style="enable-background: new 0 0 512 512"
              xml:space="preserve"
              class="w-8 h-8 lg:w-14 lg:h-14"
            >
              <g>
                <g>
                  <g>
                    <path
                      d="M324.267,332.8c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
				s8.533-3.823,8.533-8.533V332.8z"
                    />
                    <path
                      d="M315.733,426.667c-4.71,0-8.533,3.823-8.533,8.533v34.133c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533V435.2
				C324.267,430.49,320.444,426.667,315.733,426.667z"
                    />
                    <path
                      d="M358.4,230.4c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
				s8.533-3.823,8.533-8.533V230.4z"
                    />
                    <path
                      d="M324.267,281.6c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
				s8.533-3.823,8.533-8.533V281.6z"
                    />
                    <path
                      d="M324.267,384c0-4.71-3.823-8.533-8.533-8.533S307.2,379.29,307.2,384v17.067c0,4.71,3.823,8.533,8.533,8.533
				s8.533-3.823,8.533-8.533V384z"
                    />
                    <path
                      d="M358.4,281.6c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
				s8.533-3.823,8.533-8.533V281.6z"
                    />
                    <path
                      d="M290.133,230.4c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
				s8.533-3.823,8.533-8.533V230.4z"
                    />
                    <path
                      d="M324.267,230.4c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
				s8.533-3.823,8.533-8.533V230.4z"
                    />
                    <path
                      d="M358.4,145.067V128c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
				S358.4,149.777,358.4,145.067z"
                    />
                    <path
                      d="M290.133,281.6c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
				s8.533-3.823,8.533-8.533V281.6z"
                    />
                    <path
                      d="M392.533,128c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
				s8.533-3.823,8.533-8.533V128z"
                    />
                    <path
                      d="M281.6,324.267c-4.71,0-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533V332.8
				C290.133,328.09,286.31,324.267,281.6,324.267z"
                    />
                    <path
                      d="M426.667,384c0-4.71-3.823-8.533-8.533-8.533S409.6,379.29,409.6,384v17.067c0,4.71,3.823,8.533,8.533,8.533
				s8.533-3.823,8.533-8.533V384z"
                    />
                    <path
                      d="M418.133,426.667c-4.71,0-8.533,3.823-8.533,8.533v34.133c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533V435.2
				C426.667,430.49,422.844,426.667,418.133,426.667z"
                    />
                    <path
                      d="M452.267,324.267c-4.71,0-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
				c4.71,0,8.533-3.823,8.533-8.533V332.8C460.8,328.09,456.977,324.267,452.267,324.267z"
                    />
                    <path
                      d="M315.733,153.6c4.71,0,8.533-3.823,8.533-8.533v-36.002L409.6,87.731V230.4c0,3.558,2.21,6.741,5.538,7.996
				l62.729,23.518v87.953c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533V256c0-3.558-2.21-6.741-5.538-7.996
				l-62.729-23.518V59.733c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v10.402l-95.94,23.987
				c-3.797,0.947-6.46,4.361-6.46,8.277v42.667C307.2,149.777,311.023,153.6,315.733,153.6z"
                    />
                    <path
                      d="M460.8,281.6c0-4.71-3.823-8.533-8.533-8.533c-4.71,0-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
				c4.71,0,8.533-3.823,8.533-8.533V281.6z"
                    />
                    <path
                      d="M426.667,281.6c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
				s8.533-3.823,8.533-8.533V281.6z"
                    />
                    <path
                      d="M358.4,384c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
				s8.533-3.823,8.533-8.533V384z"
                    />
                    <path
                      d="M426.667,332.8c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
				s8.533-3.823,8.533-8.533V332.8z"
                    />
                    <path
                      d="M349.867,426.667c-4.71,0-8.533,3.823-8.533,8.533v34.133c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533V435.2
				C358.4,430.49,354.577,426.667,349.867,426.667z"
                    />
                    <path
                      d="M358.4,332.8c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
				s8.533-3.823,8.533-8.533V332.8z"
                    />
                    <path
                      d="M384,477.867c4.71,0,8.533-3.823,8.533-8.533V196.267c0-3.917-2.671-7.33-6.46-8.277L249.54,153.856
				c-2.534-0.64-5.257-0.06-7.322,1.553c-2.074,1.613-3.285,4.096-3.285,6.724v187.733c0,4.71,3.823,8.533,8.533,8.533
				s8.533-3.823,8.533-8.533V173.065l119.467,29.867v266.402C375.467,474.044,379.29,477.867,384,477.867z"
                    />
                    <path
                      d="M187.733,179.2c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
				s8.533-3.823,8.533-8.533V179.2z"
                    />
                    <path
                      d="M187.733,128c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
				s8.533-3.823,8.533-8.533V128z"
                    />
                    <path
                      d="M119.467,384c0-4.71-3.823-8.533-8.533-8.533S102.4,379.29,102.4,384v17.067c0,4.71,3.823,8.533,8.533,8.533
				s8.533-3.823,8.533-8.533V384z"
                    />
                    <path
                      d="M42.667,307.2c4.71,0,8.533-3.823,8.533-8.533V256h85.333v213.333c0,4.71,3.823,8.533,8.533,8.533
				c4.71,0,8.533-3.823,8.533-8.533V247.467c0-4.71-3.823-8.533-8.533-8.533h-102.4c-4.71,0-8.533,3.823-8.533,8.533v51.2
				C34.133,303.377,37.956,307.2,42.667,307.2z"
                    />
                    <path
                      d="M136.533,179.2v17.067c0,4.71,3.823,8.533,8.533,8.533c4.71,0,8.533-3.823,8.533-8.533V179.2
				c0-4.71-3.823-8.533-8.533-8.533C140.356,170.667,136.533,174.49,136.533,179.2z"
                    />
                    <path
                      d="M187.733,76.8c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
				s8.533-3.823,8.533-8.533V76.8z"
                    />
                    <path
                      d="M110.933,426.667c-4.71,0-8.533,3.823-8.533,8.533v34.133c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533V435.2
				C119.467,430.49,115.644,426.667,110.933,426.667z"
                    />
                    <path
                      d="M503.467,494.933H486.4v-52.412c14.677-3.814,25.6-17.067,25.6-32.922c0-18.825-15.309-34.133-34.133-34.133
				s-34.133,15.309-34.133,34.133c0,15.855,10.923,29.107,25.6,32.922v52.412H256v-34.995c19.447-3.968,34.133-21.197,34.133-41.805
				c0-23.526-19.14-42.667-42.667-42.667c-23.526,0-42.667,19.14-42.667,42.667c0,20.608,14.686,37.837,34.133,41.805v34.995H51.2
				v-86.195c19.447-3.968,34.133-21.197,34.133-41.805c0-23.526-19.14-42.667-42.667-42.667C19.14,324.267,0,343.407,0,366.933
				c0,20.608,14.686,37.837,34.133,41.805v86.195h-25.6c-4.71,0-8.533,3.823-8.533,8.533S3.823,512,8.533,512h494.933
				c4.71,0,8.533-3.823,8.533-8.533S508.177,494.933,503.467,494.933z M17.067,366.933c0-14.114,11.486-25.6,25.6-25.6
				s25.6,11.486,25.6,25.6s-11.486,25.6-25.6,25.6S17.067,381.047,17.067,366.933z M221.867,418.133c0-14.114,11.486-25.6,25.6-25.6
				s25.6,11.486,25.6,25.6s-11.486,25.6-25.6,25.6S221.867,432.247,221.867,418.133z M460.8,409.6
				c0-9.412,7.654-17.067,17.067-17.067s17.067,7.654,17.067,17.067c0,9.412-7.654,17.067-17.067,17.067S460.8,419.012,460.8,409.6z
				"
                    />
                    <path
                      d="M68.267,435.2v34.133c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533V435.2c0-4.71-3.823-8.533-8.533-8.533
				S68.267,430.49,68.267,435.2z"
                    />
                    <path
                      d="M119.467,281.6c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
				s8.533-3.823,8.533-8.533V281.6z"
                    />
                    <path
                      d="M119.467,332.8c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
				s8.533-3.823,8.533-8.533V332.8z"
                    />
                    <path
                      d="M68.267,281.6v17.067c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533V281.6c0-4.71-3.823-8.533-8.533-8.533
				S68.267,276.89,68.267,281.6z"
                    />
                    <path
                      d="M187.733,332.8c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
				s8.533-3.823,8.533-8.533V332.8z"
                    />
                    <path
                      d="M221.867,230.4c0-4.71-3.823-8.533-8.533-8.533c-4.71,0-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
				c4.71,0,8.533-3.823,8.533-8.533V230.4z"
                    />
                    <path
                      d="M221.867,179.2c0-4.71-3.823-8.533-8.533-8.533c-4.71,0-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
				c4.71,0,8.533-3.823,8.533-8.533V179.2z"
                    />
                    <path
                      d="M221.867,281.6c0-4.71-3.823-8.533-8.533-8.533c-4.71,0-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
				c4.71,0,8.533-3.823,8.533-8.533V281.6z"
                    />
                    <path
                      d="M213.333,324.267c-4.71,0-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
				c4.71,0,8.533-3.823,8.533-8.533V332.8C221.867,328.09,218.044,324.267,213.333,324.267z"
                    />
                    <path
                      d="M221.867,128c0-4.71-3.823-8.533-8.533-8.533c-4.71,0-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
				c4.71,0,8.533-3.823,8.533-8.533V128z"
                    />
                    <path
                      d="M187.733,384c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
				s8.533-3.823,8.533-8.533V384z"
                    />
                    <path
                      d="M110.933,221.867c4.71,0,8.533-3.823,8.533-8.533V150.34l29.414-14.703c2.893-1.451,4.719-4.403,4.719-7.637V56.474
				l42.667-21.333l42.667,21.333V128c0,4.71,3.823,8.533,8.533,8.533S256,132.71,256,128V51.2c0-3.234-1.826-6.187-4.719-7.637
				L204.8,20.326V8.533c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v11.793l-46.481,23.236
				c-2.893,1.451-4.719,4.403-4.719,7.637v71.526l-29.414,14.703c-2.893,1.451-4.719,4.403-4.719,7.637v68.267
				C102.4,218.044,106.223,221.867,110.933,221.867z"
                    />
                    <path
                      d="M187.733,281.6c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
				s8.533-3.823,8.533-8.533V281.6z"
                    />
                    <path
                      d="M179.2,426.667c-4.71,0-8.533,3.823-8.533,8.533v34.133c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533V435.2
				C187.733,430.49,183.91,426.667,179.2,426.667z"
                    />
                    <path
                      d="M221.867,76.8c0-4.71-3.823-8.533-8.533-8.533c-4.71,0-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
				c4.71,0,8.533-3.823,8.533-8.533V76.8z"
                    />
                    <path
                      d="M187.733,230.4c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533
				s8.533-3.823,8.533-8.533V230.4z"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </span>
        </div>
        <div class="border border-gray-700 my-6"></div>
        <div class="flex flex-col lg:flex-row items-center">
          <div class="w-full">
            <h2 class="text-lg lg:text-2xl font-bold text-gray-100">
              Kindly, select your city
            </h2>
            <p class="text-xs lg:text-base text-gray-100">
              We are currently servicing only in selected cities in India
            </p>
          </div>
          <div class="w-full md:w-1/4 md:mb-0 relative ml-auto mt-5 lg:mt-0">
            <input
              class="appearance-none block w-full px-3 py-3 border-2 border-gray-400 text-base leading-6 rounded-md placeholder-gray-300 focus:outline-none focus:placeholder-gray-300 focus:border-teal-500 transition duration-150 ease-in-out sm:flex-1 bg-gray-800"
              type="text"
              required="required"
              v-model="this.city"
              placeholder="Please select your city"
              @focus="showCityPicker = !showCityPicker"
              readonly
            />
            <transition name="fade">
              <div
                v-if="showCityPicker"
                class="z-20 absolute normal-case bg-white border left-0 right-0 w-full text-left mb-4 mt-2 rounded-lg shadow overflow-hidden z-10 overflow-y-auto"
                style="max-height: 32rem"
              >
                <div class="flex flex-col" ref="results">
                  <a
                    v-for="(city, index) in cities"
                    :key="index"
                    @click="selectCity(city)"
                    class="border-b border-gray-400 text-xl cursor-pointer p-4 hover:bg-blue-100 font-semibold text-gray-700"
                    >{{ city }}</a
                  >
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Snackbar",
  data() {
    return {
      showCityPicker: false,
      cities: ["Mumbai", "Pune", "Ahmedabad", "Bangalore", "Delhi", "Nagpur"],
      REPAIR_CITY_KEY: "repair-city-key",
      city: null,
    };
  },
  methods: {
    onCloseButton(event) {
      this.$emit("clicked");
    },
    selectCity(city) {
      this.onCloseButton();
      this.city = city;
      localStorage.setItem(this.REPAIR_CITY_KEY, city);
    },
  },
  created() {
    if (localStorage.getItem(this.REPAIR_CITY_KEY)) {
      document.querySelector("body").classList.add("stop-scrolling");
    } else {
      document.querySelector("body").classList.remove("stop-scrolling");
    }
  },
  beforeDestroy() {
    document.querySelector("body").classList.remove("stop-scrolling");
  },
};
</script>

<style>
.stop-scrolling {
  overflow: hidden;
}

.snackbar {
  z-index: 100;
  top: 0;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.185);
}

.snackbar-inner {
  padding: 30px 10px;
  color: white;
  font-size: 14px;
}

.snackbar-logo {
  width: 60px;
}

@media (min-width: 1024px) {
  .snackbar {
    top: 0;
    font-size: 16px;
    width: 100%;
  }

  .snackbar-logo {
    width: 100px;
  }
}
</style>