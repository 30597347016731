import axios from "axios";

const state = {
  models: [],
};
const getters = {
  allModels: (state) => {
    return state.models;
  },
};
const actions = {
  async fetchModels({ commit, rootState }, brandID) {
    const response = await axios.get(
      `${rootState.serverURL}/apiV2/brands/${brandID}`
    );

    commit("setModels", response.data);
  },
};
const mutations = {
  setModels: (state, models) => {
    state.models = models;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
