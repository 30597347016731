<template>
  <section class="mb-20 lg:mb-40 text-center relative px-5">
    <img
      src="../../assets/images/particles-yellow-4.png"
      class="absolute floating-particles-2 hidden lg:block"
      v-rellax="{ speed: 1 }"
      alt
    />
    <h2 class="font-bold text-gray-800 text-3xl md:text-4xl leading-none">
      Why Choose Us?
    </h2>
    <p class="mt-5 lg:text-xl text-gray-700 max-w-4xl mx-auto">
      Our customer centric approach makes us stand apart from the league. We
      believe in nurturing our association with every client through a
      customized methodology and best in class services.
    </p>

    <div class="py-4 lg:py-12 text-left">
      <div class="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="mt-10">
          <ul class="md:grid md:grid-cols-2 md:col-gap-8 md:row-gap-10">
            <li>
              <div class="flex">
                <div class="flex-shrink-0">
                  <div
                    class="flex items-center justify-center h-12 w-12 rounded-md bg-yellow-400 text-white"
                  >
                    <svg
                      class="h-6 w-6"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                      />
                    </svg>
                  </div>
                </div>
                <div class="ml-4">
                  <h5
                    class="font-bold text-lg leading-6 font-medium text-gray-800"
                  >
                    DOORSTEP PICKUP
                  </h5>
                  <p class="mt-2 text-base leading-6 text-gray-600">
                    We made a hassle-free process for you. One of our Repair Phone
                    executives will reach to your preferred location to repair
                    your mobile phone or tablet.
                  </p>
                </div>
              </div>
            </li>
            <li class="mt-10 md:mt-0">
              <div class="flex">
                <div class="flex-shrink-0">
                  <div
                    class="flex items-center justify-center h-12 w-12 rounded-md bg-yellow-400 text-white"
                  >
                    <svg
                      class="h-6 w-6"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"
                      />
                    </svg>
                  </div>
                </div>
                <div class="ml-4">
                  <h5
                    class="text-lg leading-6 font-medium text-gray-800 font-bold"
                  >
                    DIAGNOSIS & REPAIR
                  </h5>
                  <p class="mt-2 text-base leading-6 text-gray-600">
                    Our certified mobile technician will diagnose and repair
                    your mobile in our well-equipped lab using certified tools
                    and quality parts, if we are unable to repair at your home or office. 
                  </p>
                </div>
              </div>
            </li>
            <li class="mt-10">
              <div class="flex">
                <div class="flex-shrink-0">
                  <div
                    class="flex items-center justify-center h-12 w-12 rounded-md bg-yellow-400 text-white"
                  >
                    <svg
                      class="h-6 w-6"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M13 10V3L4 14h7v7l9-11h-7z"
                      />
                    </svg>
                  </div>
                </div>
                <div class="ml-4">
                  <h5
                    class="text-lg leading-6 font-medium text-gray-800 font-bold"
                  >
                    QUALITY CHECK
                  </h5>
                  <p class="mt-2 text-base leading-6 text-gray-600">
                    We believe in providing 100% satisfaction to our customers.
                    Once your mobile is repaired we sent it to our mobile
                    experts for quality check.
                  </p>
                </div>
              </div>
            </li>
            <li class="mt-10">
              <div class="flex">
                <div class="flex-shrink-0">
                  <div
                    class="flex items-center justify-center h-12 w-12 rounded-md bg-yellow-400 text-white"
                  >
                    <svg
                      class="h-6 w-6"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                      />
                    </svg>
                  </div>
                </div>
                <div class="ml-4">
                  <h5
                    class="text-lg leading-6 font-medium text-gray-800 font-bold"
                  >
                    FAST DELIVERY AT DOORSTEP
                  </h5>
                  <p class="mt-2 text-base leading-6 text-gray-600">
                    Incase we had to repair your phone in our lab, one of our
                    Repair Phone executives will deliver your mobile phone at your
                    doorstep.
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Section2",
};
</script>

<style>
</style>
