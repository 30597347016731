import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import moment from "moment";
import VueRellax from "vue-rellax";
import firebase from "firebase";
import Meta from 'vue-meta';
 
// Importing CSS
import "./assets/styles/tailwind/tailwind.css";
import "./assets/styles/main.css";

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyAQWH84UFAGtJfO7vG9IW6ZM-JQw5uqbVs",
  authDomain: "repair-phone.firebaseapp.com",
  databaseURL: "https://repair-phone.firebaseio.com",
  projectId: "repair-phone",
  storageBucket: "repair-phone.appspot.com",
  messagingSenderId: "313618000850",
  appId: "1:313618000850:web:d1fd4c67eca7c9365c360b",
  measurementId: "G-SKW0Y9RS1J",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

Vue.config.devtools = true; // Disable the Vue Devtools to inspect the app
Vue.config.productionTip = false;

// Vue Rellax
Vue.use(VueRellax);

// Vue Meta
Vue.use(Meta);

// Moment JS
Vue.prototype.moment = moment;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
