<template>
  <div class="stepper-row flex px-5">
    <div class="step flex-1">
      <p class="text-gray-800 text-xs lg:text-lg leading-tight">
        Select
        <br />
        <b class="text-sm lg:text-2xl step-title">Brand</b>
      </p>
      <div class="step-bottom flex items-center mt-3">
        <div
          class="step-bottom-circle inline-block mr-2 transition duration-500"
          :class="[(stepCounter == 1 ? 'current' : ''), (stepCounter > 1 ? 'completed current' : '')]"
        ></div>
        <div class="step-bottom-line flex-1 flex-1" :class="[(stepCounter > 1 ? 'completed' : '')]"></div>
      </div>
    </div>
    <div class="step flex-1">
      <p class="text-gray-800 text-xs lg:text-lg leading-tight">
        Select
        <br />
        <b class="text-sm lg:text-2xl step-title">Mobile</b>
      </p>
      <div class="step-bottom flex items-center mt-3">
        <div
          class="step-bottom-circle inline-block mx-2"
          :class="[(stepCounter == 2 ? 'current' : ''), (stepCounter > 2 ? 'completed current' : '')]"
        ></div>
        <div class="step-bottom-line flex-1" :class="[(stepCounter > 2 ? 'completed' : '')]"></div>
      </div>
    </div>
    <div class="step flex-1">
      <p class="text-gray-800 text-xs lg:text-lg leading-tight">
        Select
        <br />
        <b class="text-sm lg:text-2xl step-title">Issues</b>
      </p>
      <div class="step-bottom flex items-center mt-3">
        <div
          class="step-bottom-circle inline-block mx-2"
          :class="[(stepCounter == 3 ? 'current' : ''), (stepCounter > 3 ? 'completed current' : '')]"
        ></div>
        <div class="step-bottom-line flex-1" :class="[(stepCounter > 3 ? 'completed' : '')]"></div>
      </div>
    </div>
    <div class="step flex-1">
      <p class="text-gray-800 text-xs lg:text-lg leading-tight">
        Enter
        <br />
        <b class="text-sm lg:text-2xl step-title">Details</b>
      </p>
      <div class="step-bottom flex items-center mt-3">
        <div
          class="step-bottom-circle inline-block mx-2"
          :class="[(stepCounter == 4 ? 'current' : ''), (stepCounter > 4 ? 'completed current' : '')]"
        ></div>
        <div class="step-bottom-line flex-1" :class="[(stepCounter > 4 ? 'completed' : '')]"></div>
      </div>
    </div>
    <div class="step flex-1">
      <p class="text-gray-800 text-xs lg:text-lg leading-tight">
        Schedule
        <br />
        <b class="text-sm lg:text-2xl step-title">Appointment</b>
      </p>
      <div class="step-bottom flex items-center mt-3">
        <div class="step-bottom-circle inline-block mx-2" :class="[(stepCounter == 5 ? 'current' : ''), (stepCounter > 5 ? 'completed current' : '')]"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Stepper",
  computed: {
    ...mapGetters(["stepCounter"])
  }
};
</script>

<style>
.step-title{
  font-size: 16px;
}
</style>
