<template>
  <div class="my-5 lg:mt-20 px-5">
    <h2 class="font-bold text-gray-800 text-2xl lg:text-3xl leading-none">
      Select Your Device Brand
    </h2>
    <div class="relative w-full mt-5 lg:w-3/5">
      <input
        type="text"
        v-model="searchTerm"
        placeholder="Search for your device's brand"
        class="transition duration-200 bg-background-form border-gray-500 rounded px-4 pl-10 py-3 outline-none focus:border-orange-400 w-full border-2"
      />
      <div class="absolute top-0 ml-3" style="top: 15px">
        <svg
          fill="currentColor"
          class="text-gray-500 h-5 w-5"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path
            class="heroicon-ui"
            d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"
          />
        </svg>
      </div>
      <div
        class="absolute right-0 text-2xl mr-3 cursor-pointer text-gray-600 hover:text-gray-800"
        style="top: 50%; transform: translate(0, -50%)"
      >
        &times;
      </div>
    </div>
    <transition name="fade">
      <div
        class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 md:gap-6 mt-5 lg:mt-10"
      >
        <div
          v-for="brands in filteredPosts"
          :key="brands._id"
          @click="
            incrementSteps(1);
            setSelectedBrand(brands.name);
            setSelectedBrandId(brands._id);
          "
          class="bg-white border-dashed border-2 hover:border-red-500 rounded-md p-2 lg:py-4 lg:px-3 flex transition-all duration-300 transform cursor-pointer items-center"
        >
          <div class="ml-0 lg:ml-0 md:m-0 inline-block">
            <picture>
              <source
                :srcset="
                  'https://assets.cashmen.in/res/brandimages/v2/' +
                  brands._id +
                  '.webp'
                "
                type="image/webp"
              />
              <source
                :srcset="
                  'https://assets.cashmen.in/res/brandimages/v2/' +
                  brands._id +
                  '.png'
                "
                type="image/jpeg"
              />
              <img
                class="h-10 md:h-16"
                :src="
                  'https://assets.cashmen.in/res/brandimages/v2/' +
                  brands._id +
                  '.png'
                "
              />
            </picture>
          </div>
          <div class="ml-5">
            <h1 class="text-gray-700 font-bold text-lg lg:text-xl xl:text-2xl">
              {{ brands.name }}
            </h1>
            <p
              v-if="brands.moto.length <= 20"
              class="text-gray-600 relative hidden lg:block"
            >
              <svg
                class="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-5 w-5 text-gray-800 opacity-25"
                fill="currentColor"
                viewBox="0 0 32 32"
              >
                <path
                  d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z"
                />
              </svg>
              {{ brands.moto.slice(0, 20) }}
            </p>
            <p
              v-if="brands.moto.length > 20"
              class="text-gray-600 relative hidden lg:block"
            >
              <svg
                class="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-5 w-5 text-gray-800 opacity-25"
                fill="currentColor"
                viewBox="0 0 32 32"
              >
                <path
                  d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z"
                />
              </svg>
              {{ brands.moto.slice(0, 20) }}...
            </p>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Step1",
  data() {
    return {
      searchTerm: "",
    };
  },
  computed: {
    ...mapGetters(["allBrands"]),
    filteredPosts() {
      return this.allBrands.filter((device) => {
        var totalMatches = 0;
        this.searchTerm.toLowerCase().trim().split(" ");
        for (let i = 0; i < this.searchTerm.length; i++) {
          if (
            device.name.toLowerCase().includes(this.searchTerm[i].toLowerCase())
          ) {
            totalMatches++;
          }
        }
        return totalMatches == this.searchTerm.length;
      });
    },
  },
  methods: {
    ...mapActions([
      "fetchBrands",
      "incrementSteps",
      "setSelectedBrand",
      "setSelectedBrandId",
    ]),
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  metaInfo() {
    return {
      title:
        "Repair Phone - Select Brand",
      meta: [
        {
          name:
            "Repair Phone - Select Brand",
          content:
            "Repair Phone - Select Brand",
        },
      ],
    };
  },
  created() {
    this.fetchBrands();
    this.scrollToTop();
  },
};
</script>

<style>
</style>
