<template>
  <section class="section-1 hidden xl:flex mt-20 items-center">
    <div class="mr-5 flex-1 flex items-center">
      <img class="card mr-5 flex-1" src="../../assets/images/card-1.png" alt />
      <div class="ml-5 mt-20 flex-1" v-rellax="{ speed: 2 }">
        <img class="card" src="../../assets/images/card-2.png" alt />
        <img class="card mt-10" src="../../assets/images/card-3.png" alt />
      </div>
    </div>
    <div class="ml-5 flex-1 relative">
      <h2 class="font-bold text-gray-800 text-4xl leading-none">
        What do we do?
      </h2>
      <p class="mt-5 text-xl text-gray-700 max-w-xl">
        Our certified mobile technician will diagnose and repair your mobile at
        your doorstep, however, if it'll take time we will further diagnose it
        in our well-equipped lab using certified tools and quality parts.
      </p>
      <p class="mt-5 text-xl font-semibold text-gray-700 max-w-xl">
        We believe in providing 100% satisfaction to our customers.
      </p>
      <router-link
        to="/about-us"
        class="mt-5 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center transition duration-500"
      >
        <span>More about us</span>
        <svg
          class="fill-current w-4 h-4 ml-2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path
            d="M16.172 9l-6.071-6.071 1.414-1.414L20 10l-.707.707-7.778 7.778-1.414-1.414L16.172 11H0V9z"
          />
        </svg>
      </router-link>

      <img
        src="../../assets/images/particles-yellow-3.png"
        class="absolute floating-particles hidden lg:block"
        v-rellax="{ speed: 2 }"
        alt
      />
    </div>
  </section>
</template>

<script>
export default {
  name: "Section1",
};
</script>

<style>
</style>
