<template>
  <div
    aria-expanded="true"
    data-modal="modal-register"
    class="v--modal-overlay"
  >
    <div class="v--modal-background-click">
      <div class="v--modal-background-click" id="recaptcha-container"></div>
      <transition name="fade">
        <div class="v--modal-box v--modal rounded-md login-modal-container">
          <div class="px-10 py-8">
            <div
              class="absolute text-2xl mr-3 cursor-pointer text-gray-600 hover:text-gray-800"
              style="right: 6%; top: 16%; transform: translate(0px, -50%)"
              @click.self="toggleLoginModal"
            >
              ×
            </div>
            <h2
              class="mb-4 uppercase tracking-wide text-xl text-gray-700 font-bold"
            >
              {{ otpsection ? "SMS SENT SUCCESSFULLY" : "LOGIN / REGISTER" }}
            </h2>
            <div class="form-group mb-4" v-if="!otpsection">
              <label
                for="number"
                class="block font-normal tracking-wide text-s mb-1"
                >We will be sending an OTP to your mentioned number</label
              >
              <input
                ref="number"
                v-model.number="getAppointmentData.phoneNumber"
                type="text"
                name="number"
                class="border px-4 py-2 w-full rounded bg-gray-200"
                placeholder="eg: 088 880 8888"
                disabled
              />
            </div>

            <div class="form-group mb-4" v-if="otpsection">
              <label
                for="otp"
                class="block font-normal capitalize tracking-wide text-s mb-1"
                >Please check your phone and enter correct OTP</label
              >
              <input
                ref="otp"
                v-model="otp"
                type="text"
                id="otp"
                name="otp"
                class="border px-4 py-2 w-full rounded bg-gray-200"
                placeholder="eg: 123456"
                :disabled="showLoader"
              />
            </div>

            <div class="form-group text-center">
              <div class="inline-flex rounded-md shadow">
                <button
                  :disabled="showLoader"
                  :class="
                    showLoader
                      ? 'bg-gray-300 cursor-default'
                      : 'bg-green-500 cursor-pointer'
                  "
                  @click="otpsection ? verifyOtp() : sendOtp()"
                  class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white focus:outline-none focus:shadow-outline transition duration-150 ease-in-out font-semibold"
                >
                  {{ otpsection ? "Submit OTP" : "Send OTP On Your Number" }}
                  <svg
                    v-if="showLoader"
                    id="loading"
                    class="ml-2"
                    fill="currentColor"
                    width="18.349px"
                    height="18.35px"
                    viewBox="0 0 26.349 26.35"
                    style="enable-background: new 0 0 26.349 26.35"
                    xml:space="preserve"
                  >
                    <g>
                      <g>
                        <circle cx="13.792" cy="3.082" r="3.082" />
                        <circle cx="13.792" cy="24.501" r="1.849" />
                        <circle cx="6.219" cy="6.218" r="2.774" />
                        <circle cx="21.365" cy="21.363" r="1.541" />
                        <circle cx="3.082" cy="13.792" r="2.465" />
                        <circle cx="24.501" cy="13.791" r="1.232" />
                        <path
                          d="M4.694,19.84c-0.843,0.843-0.843,2.207,0,3.05c0.842,0.843,2.208,0.843,3.05,0c0.843-0.843,0.843-2.207,0-3.05
			C6.902,18.996,5.537,18.988,4.694,19.84z"
                        />
                        <circle cx="21.364" cy="6.218" r="0.924" />
                      </g>
                    </g>
                  </svg>
                </button>
              </div>
            </div>
            <div
              class="text-sm font-normal text-center mt-4"
              v-if="tryAgainText"
            >
              <p>
                Didn't receive an OTP?
                <button
                  @click="sendOtp()"
                  class="text-green-600 hover:text-green-800"
                >
                  Try Again
                </button>
              </p>
            </div>
          </div>
          <!---->
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";

// Vuex
import { mapGetters, mapActions } from "vuex";
export default {
  name: "OTP",
  data() {
    return {
      otpsection: false,
      showLoader: false,
      phoneNumber: "",
      otp: "",
      tryAgainText: false,
    };
  },
  computed: {
    ...mapGetters(["getAppointmentData"]),
  },
  methods: {
    ...mapActions(["toggleLoginModal", "incrementSteps"]),

    // Init Captcha
    initReCaptcha() {
      setTimeout(() => {
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
          "recaptcha-container",
          {
            size: "invisible",
            callback: function () {
              // reCAPTCHA solved, allow signInWithPhoneNumber.
            },
            "expired-callback": function () {
              // Response expired. Ask user to solve reCAPTCHA again.
            },
          }
        );
        //
        this.appVerifier = window.recaptchaVerifier;
      }, 1000);
    },

    // Send OTP
    sendOtp() {
      this.tryAgainText = true;
      this.showLoader = true;
      this.phoneNumber = this.getAppointmentData.phoneNumber;
      if (this.phoneNumber.length != 10) {
        alert("Invalid Phone Number Format !");
        this.showLoader = false;
        this.tryAgainText = false;
        return;
      } else {
        this.showLoader = true;
        this.tryAgainText = true;
        let countryCode = "+91"; //india
        let phoneNumber = countryCode + this.phoneNumber;
        let appVerifier = this.appVerifier;
        this.showOtpModal = true;

        let self = this;
        firebase
          .auth()
          .signInWithPhoneNumber(phoneNumber, appVerifier)
          .then(function (confirmationResult) {
            // SMS sent. Prompt user to type the code from the message, then sign the
            // user in with confirmationResult.confirm(code).
            window.confirmationResult = confirmationResult;
            self.showLoader = false;
            self.tryAgainText = false;
            self.otpsection = true;
          })
          .catch(function (error) {
            // Error; SMS not sent
            alert("Error ! SMS not sent");
            console.log(error);
          });
      }
    },

    // Verify the OTP entered
    verifyOtp() {
      this.showLoader = true;
      if (!this.otp == "") {
        const self = this;
        if (this.phoneNumber.length != 10 || this.otp.length != 6) {
          this.otpsection = false;
          this.showLoader = false;
          alert("Invalid Phone Number/OTP Format !");
        } else {
          let code = this.otp;
          window.confirmationResult
            .confirm(code)
            .then(function (result) {
              // User signed in successfully.
              self.showLoader = false;
              self.toggleLoginModal();

              var user = result.user;
              user
                .getIdToken(/* forceRefresh */ true)
                .then(function () {
                  // Move Next if no errors
                  self.incrementSteps(1);
                })
                .catch(function (error) {
                  // Handle error
                  console.log(error);
                });
            })
            .catch(function (e) {
              self.otpsection = false;
              self.showLoader = false;
              self.otp = "";
              alert("OTP is incorrect, please try again!" + e);
            });
        }
      } else {
        alert("Please enter the correct OTP");
      }
    },
  },
  created() {
    this.initReCaptcha();
  },
};
</script>

<style>
</style>
