import Vue from "vue";
import Vuex from "vuex";

// Modules
import brands from "./modules/Brands";
import models from "./modules/Models";
import stepper from "./modules/Stepper";
import address from "./modules/Address";
import appointmentData from "./modules/Form";
import OTP from "./modules/OTP";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    serverURL: "https://api2.cashmen.in"
  },
  getters: {
    getServerURL: (state) => {
      return state.serverURL;
    },
  },
  mutations: {},
  actions: {},
  modules: {
    brands,
    models,
    stepper,
    address,
    appointmentData,
    OTP,
  },
});
