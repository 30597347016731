<template>
  <div class="my-5 lg:mt-20 px-5">
    <div class="flex items-center">
      <div>
        <h2 class="font-bold text-gray-800 text-2xl lg:text-3xl leading-none">
          Kindly, Enter Your Details
        </h2>
        <p class="mt-2 text-sm lg:text-base text-gray-600">
          We will be sending an OTP SMS to your device to verify the number
        </p>
      </div>
    </div>

    <form class="w-full mt-5 lg:mt-10">
      <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <label
            class="block uppercase tracking-wide text-gray-700 text-md font-bold mb-2"
            for="first-name"
            >First Name</label
          >
          <input
            class="appearance-none block w-full px-3 py-3 border-2 text-base leading-6 rounded-md placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:border-orange-400 transition duration-150 ease-in-out sm:flex-1"
            type="text"
            id="first-name"
            v-model="firstName"
            placeholder="Jane"
          />
          <div v-if="errors.firstName != null">
            <svg
              fill="currentColor"
              class="text-red-600 h-4 h-4 inline-flex items-center"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zM9 5v6h2V5H9zm0 8v2h2v-2H9z"
              />
            </svg>
            <label
              class="inline-flex items-center tracking-wide text-red-600 text-sm font-semibold mt-2 ml-2"
              for="grid-first-name"
              >{{ errors.firstName }}</label
            >
          </div>
        </div>
        <div class="w-full md:w-1/2 px-3">
          <label
            class="block uppercase tracking-wide text-gray-700 text-md font-bold mb-2"
            for="last-name"
            >Last Name</label
          >
          <input
            class="appearance-none block w-full px-3 py-3 border-2 text-base leading-6 rounded-md placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:border-orange-400 transition duration-150 ease-in-out sm:flex-1"
            id="last-name"
            type="text"
            v-model="lastName"
            placeholder="Doe"
          />
          <div v-if="errors.lastName != null">
            <svg
              fill="currentColor"
              class="text-red-600 h-4 h-4 inline-flex items-center"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zM9 5v6h2V5H9zm0 8v2h2v-2H9z"
              />
            </svg>
            <label
              class="inline-flex items-center tracking-wide text-red-600 text-sm font-semibold mt-2 ml-2"
              for="grid-first-name"
              >{{ errors.lastName }}</label
            >
          </div>
        </div>
      </div>
      <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <label
            class="block uppercase tracking-wide text-gray-700 text-md font-bold mb-2"
            for="number"
            >Phone Number</label
          >
          <input
            id="number"
            class="appearance-none block w-full px-3 py-3 border-2 text-base leading-6 rounded-md placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:border-orange-400 transition duration-150 ease-in-out sm:flex-1"
            type="number"
            v-model="phoneNumber"
            placeholder="Eg: 122 122 1212"
          />
          <div v-if="errors.number != null">
            <svg
              fill="currentColor"
              class="text-red-600 h-4 h-4 inline-flex items-center"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zM9 5v6h2V5H9zm0 8v2h2v-2H9z"
              />
            </svg>
            <label
              class="inline-flex items-center tracking-wide text-red-600 text-sm font-semibold mt-2 ml-2"
              for="grid-first-name"
              >{{ errors.number }}</label
            >
          </div>
        </div>

        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <label
            class="block uppercase tracking-wide text-gray-700 text-md font-bold mb-2"
            for="email"
            >Email Address</label
          >
          <input
            id="email"
            class="appearance-none block w-full px-3 py-3 border-2 text-base leading-6 rounded-md placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:border-orange-400 transition duration-150 ease-in-out sm:flex-1"
            type="email"
            v-model="emailAddress"
            placeholder="Eg: joe@example.com"
          />
          <div v-if="errors.email != null">
            <svg
              fill="currentColor"
              class="text-red-600 h-4 h-4 inline-flex items-center"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zM9 5v6h2V5H9zm0 8v2h2v-2H9z"
              />
            </svg>
            <label
              class="inline-flex items-center tracking-wide text-red-600 text-sm font-semibold mt-2 ml-2"
              for="grid-first-name"
              >{{ errors.email }}</label
            >
          </div>
        </div>
      </div>
      <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full px-3 mb-6 md:mb-0">
          <label
            class="block uppercase tracking-wide text-gray-700 text-md font-bold mb-2"
            for="address"
            >Your Address</label
          >
          <textarea
            id="address"
            v-model="address"
            class="appearance-none block w-full px-3 py-3 text-base leading-6 rounded-md placeholder-gray-500 focus:outline-none border-2 focus:placeholder-gray-400 focus:border-orange-400 h-40 lg:h-30 transition duration-150 ease-in-out sm:flex-1"
            type="text"
            placeholder="Your entire correct pickup address (Please make sure that you specify the landmarks correctly)"
          ></textarea>
          <div v-if="errors.address != null">
            <svg
              fill="currentColor"
              class="text-red-600 h-4 h-4 inline-flex items-center"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zM9 5v6h2V5H9zm0 8v2h2v-2H9z"
              />
            </svg>
            <label
              class="inline-flex items-center tracking-wide text-red-600 text-sm font-semibold mt-2 ml-2"
              for="grid-first-name"
              >{{ errors.address }}</label
            >
          </div>
        </div>
      </div>
      <div class="flex flex-wrap -mx-3 mb-2">
        <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
          <label
            class="block uppercase tracking-wide text-gray-700 text-md font-bold mb-2"
            for="city"
            >City</label
          >
          <input
            id="city"
            class="appearance-none block w-full px-3 py-3 border-2 text-base leading-6 rounded-md placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:border-orange-400 transition duration-150 ease-in-out sm:flex-1 disabled:bg-gray-200 cursor-not-allowed"
            v-model="city"
            readonly
            disabled
            type="text"
            placeholder="City will be automatically selected based on pincode"
          />
        </div>
        <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0 relative">
          <label
            class="block uppercase tracking-wide text-gray-700 text-md font-bold mb-2"
            for="state"
            >State</label
          >
          <input
            id="state"
            class="appearance-none block w-full px-3 py-3 border-2 text-base leading-6 rounded-md placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:border-orange-400 transition duration-150 ease-in-out sm:flex-1 disabled:bg-gray-200 cursor-not-allowed"
            type="text"
            disabled
            v-model="state"
            placeholder="eg. Maharashtra"
            readonly
          />
        </div>
        <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
          <label
            class="block uppercase tracking-wide text-gray-700 text-md font-bold mb-2"
            for="zip"
            >Zip / Pin Code</label
          >
          <input
            id="zip"
            class="appearance-none block w-full px-3 py-3 border-2 text-base leading-6 rounded-md placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:border-orange-400 transition duration-150 ease-in-out sm:flex-1"
            type="number"
            v-model="pincode"
            placeholder="400001"
            @keyup="checkPinCode"
            maxlength="6"
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
          />

          <div v-if="errors.pincode != null">
            <svg
              fill="currentColor"
              class="text-red-600 h-4 h-4 inline-flex items-center"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zM9 5v6h2V5H9zm0 8v2h2v-2H9z"
              />
            </svg>
            <label
              class="inline-flex items-center tracking-wide text-red-600 text-sm font-semibold mt-2 ml-2"
              for="grid-first-name"
              >{{ errors.pincode }}</label
            >
          </div>
        </div>

        <div class="w-full md:w-1/3 mt-6 px-3 mb-6 md:mb-0 relative">
          <label
            class="block uppercase tracking-wide text-gray-700 text-md font-bold mb-2"
            for="grid-first-name"
            >Pickup Appointment Slot</label
          >
          <input
            class="appearance-none block w-full px-3 py-3 border-2 text-base leading-6 rounded-md placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:border-teal-500 transition duration-150 ease-in-out sm:flex-1"
            type="text"
            required="required"
            v-model="this.dateSlot"
            placeholder="Pickup Time that is suitable for you from the list"
            @focus="showAppointmentPicker = !showAppointmentPicker"
            readonly
          />
          <div v-if="errors.dateSlot != null">
            <svg
              fill="currentColor"
              class="text-red-600 h-4 h-4 inline-flex items-center"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zM9 5v6h2V5H9zm0 8v2h2v-2H9z"
              />
            </svg>
            <label
              class="inline-flex items-center tracking-wide text-red-600 text-sm font-semibold mt-2 ml-2"
              for="grid-first-name"
              >{{ errors.dateSlot }}</label
            >
          </div>
          <transition name="fade">
            <div
              v-if="showAppointmentPicker"
              class="z-20 absolute normal-case bg-white border left-0 right-0 w-4/5 text-left ml-4 mb-4 mt-2 rounded-lg shadow overflow-hidden z-10 overflow-y-auto"
              style="max-height: 32rem"
            >
              <div class="flex flex-col" ref="results">
                <a
                  v-for="(time, index) in pickupTime"
                  :key="index"
                  @click="setDateSlot(time.start, time.end)"
                  class="border-b border-gray-400 text-xl cursor-pointer p-4 hover:bg-blue-100 font-semibold text-gray-700"
                  >{{ time.start + " - " + time.end }}</a
                >
              </div>
            </div>
          </transition>
        </div>
      </div>

      <button
        @click.prevent="submitFormAndMoveNext"
        class="ml-auto mt-8 bg-green-500 hover:bg-green-600 text-gray-100 font-bold py-3 px-8 rounded text-xl inline-flex items-center transition duration-500"
      >
        <span>Send OTP</span>
        <svg
          class="fill-current w-4 h-4 ml-2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path
            d="M16.172 9l-6.071-6.071 1.414-1.414L20 10l-.707.707-7.778 7.778-1.414-1.414L16.172 11H0V9z"
          />
        </svg>
      </button>
    </form>
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapActions } from "vuex";

// Mixins
import validationMixins from "../../mixins/validation";

export default {
  name: "Step4",
  data() {
    return {
      firstName: null,
      lastName: null,
      phoneNumber: null,
      emailAddress: null,
      address: null,
      pickupdate: null,
      pickuptime: null,
      city: "",
      state: "Maharashtra",
      dateSlot: null,
      pincode: null,
      // pincode validate
      correctPincode: null,
      showAppointmentPicker: false,
      pickupTime: [],
    };
  },
  computed: {
    ...mapGetters(["allPinCodes", "areaList"]),
  },
  methods: {
    ...mapActions([
      "fetchPinCodes",
      "setAppointmentDataFromStep3",
      "toggleLoginModal",
    ]),
    submitFormAndMoveNext() {
      // Check for Errors
      if (this.validate()) {
        this.toggleLoginModal();
        // Set form data to Vuex
        this.setAppointmentDataFromStep3({
          firstName: this.firstName,
          lastName: this.lastName,
          phoneNumber: this.phoneNumber,
          emailAddress: this.emailAddress,
          address: this.address,
          city: this.city,
          state: this.state,
          pincode: this.pincode,
          dateSlot: this.dateSlot,
        });
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    generateDateSlots() {
      var currentDate = new Date();
      var firstSlotEnd = new Date();
      var secondSlotEnd = new Date();
      var thirdSlotEnd = new Date();

      firstSlotEnd.setDate(firstSlotEnd.getDate() + 3);
      secondSlotEnd.setDate(secondSlotEnd.getDate() + 6);
      thirdSlotEnd.setDate(thirdSlotEnd.getDate() + 9);

      let currentDay = this.formatDate(currentDate); // Current Date
      let firstSlot = this.formatDate(firstSlotEnd); // 3rd Date
      let secondSlot = this.formatDate(secondSlotEnd); // 6th Date
      let thirdSlot = this.formatDate(thirdSlotEnd); // 6th Date

      this.pickupTime = [
        {
          start: currentDay,
          end: firstSlot,
        },
        {
          start: firstSlot,
          end: secondSlot,
        },
        {
          start: secondSlot,
          end: thirdSlot,
        },
      ];
    },

    formatDate(date) {
      var dd = date.getDate();
      var mm = date.getMonth() + 1;
      var y = date.getFullYear();
      return dd + "/" + mm + "/" + y;
    },

    setDateSlot(start, end) {
      this.showAppointmentPicker = false;
      this.dateSlot = start + " - " + end;
    },
  },
  created() {
    // Fetch Pincodes
    this.fetchPinCodes();
    this.scrollToTop();
    this.generateDateSlots();
  },

  metaInfo() {
    return {
      title: `Repair Phone - Customer Details`,
      meta: [
        {
          name: `Repair Phone - Customer Details`,
          content: `Repair Phone - Customer Details`,
        },
      ],
    };
  },

  mixins: [validationMixins],
};
</script>

<style>
</style>
