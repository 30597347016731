<template>
  <div class="custom-container mx-auto">
    <div class="block lg:inline-flex justify-between items-center">
      <a target="_blank" href="https://ls.graphics/illustrations">
        <picture class="mx-auto lg:mr-20 misc-header-img">
          <!-- <source srcset="../assets/images/congratulate.webp" type="image/webp" /> -->
          <source srcset="../assets/images/Courier.png" type="image/jpeg" />
          <img
            class="mx-auto lg:mr-20 misc-header-img"
            src="../assets/images/Courier.png"
            alt="Order Place Image"
          />
        </picture>
      </a>
      <div class="text-center xl:text-left px-4">
        <h2
          class="text-3xl lg:text-4xl xl:text-5xl tracking-tight leading-10 font-extrabold text-gray-800 sm:leading-none z-20"
        >
          Congratulations!
        </h2>
        <h2
          class="mt-1 lg:mt-5 text-xl lg:text-3xl xl:text-4xl tracking-tight leading-10 font-extrabold text-gray-800 z-20"
        >
          Your appointment has been
          <span class="cashmen-green">successfully</span> placed
        </h2>
        <p
          class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0"
        >
          Kindly, check your email/sms for further details. We will be soon
          reaching out to your doorstep to diagnose your device.
        </p>
        <router-link
          to="/"
          type="button"
          class="mt-5 inline-flex items-center px-8 py-4 border border-gray-300 text-lg leading-5 font-medium rounded-md text-white bg-gray-800 hover:bg-gray-700 focus:outline-none focus:shadow-outline focus:border-blue-300 transition duration-250 ease-in-out"
        >
          <svg
            class="-ml-1 mr-2 h-6 w-6"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path
              d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z"
            />
          </svg>
          Book Another Checkup?
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Complete",
  created() {
    this.scrollToTop();
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
     metaInfo() {
    return {
      title:
        `Repair Phone - Order Successful`,
      meta: [
        {
          name:
            `Repair Phone - Order Successful`,
          content:
            `Repair Phone - Order Successful`,
        },
      ],
    };
  },
};
</script>

<style>
.misc-header-img {
  height: 20rem;
}

@media (min-width: 1024px) {
  .misc-header-img {
    height: 30rem;
  }
}
</style>
