const state = {
  appointmentData: {
    firstName: null,
    lastName: null,
    phoneNumber: null,
    emailAddress: null,
    address: null,
    city: null,
    state: null,
    pincode: null,
    dateSlot: null,
    agreements: false,
  },
};
const getters = {
  getAppointmentData: (state) => {
    return state.appointmentData;
  },
};
const actions = {
  setAppointmentDataFromStep3({ commit }, data) {
    commit("setAppointmentDataFromStep3", data);
  },
  agreeToAgreements({ commit }) {
    commit("agreeToAgreements");
  },
  disagreeToAgreements({ commit }) {
    commit("disagreeToAgreements");
  },
};
const mutations = {
  setAppointmentDataFromStep3: (state, data) => {
    state.appointmentData.firstName = data.firstName;
    state.appointmentData.lastName = data.lastName;
    state.appointmentData.phoneNumber = data.phoneNumber;
    state.appointmentData.emailAddress = data.emailAddress;
    state.appointmentData.address = data.address;
    state.appointmentData.city = data.city;
    state.appointmentData.state = data.state;
    state.appointmentData.dateSlot = data.dateSlot;
    state.appointmentData.pincode = data.pincode;
  },
  agreeToAgreements: (state) => {
    state.appointmentData.agreements = true;
  },
  disagreeToAgreements: (state) => {
    state.appointmentData.agreements = false;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
