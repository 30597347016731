<template>
  <div class="my-5 lg:mt-20 px-5">
    <h2 class="font-bold text-gray-800 text-2xl lg:text-3xl leading-none">
      Select Your Device
    </h2>
    <div class="relative w-full mt-5 lg:w-3/5">
      <input
        type="text"
        v-model="searchTerm"
        placeholder="Search for your device"
        class="transition duration-200 bg-background-form border-gray-500 rounded px-4 pl-10 py-3 outline-none focus:border-orange-400 w-full border-2"
      />
      <div class="absolute top-0 ml-3" style="top: 15px">
        <svg
          fill="currentColor"
          class="text-gray-500 h-5 w-5"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path
            class="heroicon-ui"
            d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"
          />
        </svg>
      </div>
      <div
        class="absolute right-0 text-2xl mr-3 cursor-pointer text-gray-600 hover:text-gray-800"
        style="top: 50%; transform: translate(0, -50%)"
      >
        &times;
      </div>
    </div>

    <div class>
      <h2
        v-if="modelsList.length > 0"
        class="inline-flex items-center mt-4 lg:mt-10 leading-8 font-extrabold tracking-tight text-gray-800 text-xl xl:text-2xl sm:leading-10"
      >
        <svg
          class="-ml-1 mr-2 h-5 w-5"
          fill="currentColor"
          viewBox="0 0 496.158 496.158"
          style="enable-background: new 0 0 496.158 496.158"
          xml:space="preserve"
        >
          <path
            style="fill: #ff4355"
            d="M248.082,0.003C111.07,0.003,0,111.061,0,248.085c0,137,111.07,248.07,248.082,248.07
	c137.006,0,248.076-111.07,248.076-248.07C496.158,111.061,385.088,0.003,248.082,0.003z"
          />
          <path
            style="fill: #ffffff"
            d="M278.767,145.419c-3.126-4.003-7.276-6.006-12.451-6.006c-4.591,0-7.716,0.879-9.375,2.637
	c-1.662,1.758-5.226,6.445-10.693,14.063c-5.47,7.617-11.744,14.502-18.823,20.654c-7.082,6.152-16.53,12.012-28.345,17.578
	c-7.91,3.712-13.429,6.738-16.553,9.082c-3.126,2.344-4.688,6.006-4.688,10.986c0,4.298,1.586,8.082,4.761,11.353
	c3.172,3.273,6.812,4.907,10.913,4.907c8.592,0,25.292-9.521,50.098-28.564V335.41c0,7.814,1.806,13.722,5.42,17.725
	c3.612,4.003,8.397,6.006,14.355,6.006c13.378,0,20.068-9.814,20.068-29.443V161.972
	C283.455,154.941,281.892,149.425,278.767,145.419z"
          />
        </svg>
        Select Device Type
      </h2>
      <div class="flex mt-2 w-2/4">
        <button
          type="button"
          @click="
            deviceType = 'mobile';
            filterList();
          "
          class="z-10 inline-flex items-center px-4 lg:px-5 py-2 lg:py-3 border border-transparent text-sm leading-5 font-medium rounded-md text-white focus:outline-none focus:shadow-outline-gray transition duration-150 ease-in-out hover:shadow-lg transition-all duration-300 transform hover:scale-105 flex-1"
          :class="
            deviceType === 'mobile' ? 'completed shadow-md' : 'bg-gray-700'
          "
          v-if="hasMobiles"
        >
          <svg
            class="-ml-1 mr-2 h-4 w-4 lg:h-5 lg:w-5"
            fill="currentColor"
            viewBox="0 0 512 512"
            xml:space="preserve"
          >
            <g>
              <g>
                <path
                  d="M384,0H128c-17.632,0-32,14.368-32,32v448c0,17.664,14.368,32,32,32h256c17.664,0,32-14.336,32-32V32
			C416,14.368,401.664,0,384,0z M256,480c-17.664,0-32-14.336-32-32s14.336-32,32-32s32,14.336,32,32S273.664,480,256,480z M384,384
			H128V64h256V384z"
                />
              </g>
            </g>
          </svg>
          {{getSelectedBrand === 'Apple' ? 'iPhone' : 'Mobiles'}}
        </button>

        <button
          type="button"
          @click="
            deviceType = 'tablet';
            filterList();
          "
          class="z-10 inline-flex items-center px-5 py-3 border border-transparent text-sm leading-5 font-medium rounded-md text-white focus:outline-none focus:shadow-outline-gray transition duration-150 ease-in-out hover:shadow-lg transition-all duration-300 transform hover:scale-105 flex-1 ml-4 lg:ml-8"
          :class="
            deviceType === 'tablet' ? 'completed shadow-md' : 'bg-gray-700'
          "
          v-if="hasTablets"
        >
          <svg
            class="-ml-1 mr-2 h-4 w-4 lg:h-5 lg:w-5"
            fill="currentColor"
            viewBox="0 0 512 512"
            xml:space="preserve"
          >
            <g>
              <g>
                <path
                  d="M394.667,0H117.333C87.936,0,64,23.936,64,53.333v405.333C64,488.064,87.936,512,117.333,512h277.333
			C424.064,512,448,488.064,448,458.667V53.333C448,23.936,424.064,0,394.667,0z M256,480c-11.755,0-21.333-9.579-21.333-21.333
			s9.579-21.333,21.333-21.333s21.333,9.579,21.333,21.333S267.755,480,256,480z M405.333,416c0,5.888-4.779,10.667-10.667,10.667
			H117.333c-5.888,0-10.667-4.779-10.667-10.667V53.333c0-5.888,4.779-10.667,10.667-10.667h277.333
			c5.888,0,10.667,4.779,10.667,10.667V416z"
                />
              </g>
            </g>
          </svg>
          {{getSelectedBrand === 'Apple' ? 'iPad' : 'Tablets'}}
        </button>
      </div>
    </div>

    <!-- Models grid -->
    <div
      class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 md:gap-6 mt-4 text-center lg:mt-20"
    >
      <div
        class="bg-white border-dashed border-2 hover:border-red-500 rounded-md px-2 lg:px-3 py-4 lg:py-6 z-10 cursor-pointer transition-all duration-300 transform"
        v-for="model in filteredDevices"
        :key="model._id"
        @click="
          setSelectedModelId(model._id);
          incrementSteps(1);
        "
      >
        <div>
          <picture class="mx-auto">
            <source
              :srcset="
                'https://assets.cashmen.in/res/deviceimages/webp/' +
                model._id +
                '.webp'
              "
              type="image/webp"
            />
            <source
              :srcset="
                'https://assets.cashmen.in/res/deviceimages/' +
                model._id +
                '.png'
              "
              type="image/jpeg"
            />
            <img
              class="mx-auto h-32 lg:h-64"
              :src="
                'https://assets.cashmen.in/res/deviceimages/' +
                model._id +
                '.png'
              "
            />
          </picture>
        </div>
        <div>
          <h1 class="text-gray-700 font-bold text-md lg:text-lg mt-4">
            {{ model.name }}
          </h1>
          <p class="text-gray-600 relative text-sm lg:text-md">
            {{ getSelectedBrand }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Step2",
  data() {
    return {
      searchTerm: "",
      modelsList: [],
      seriesList: [],
      deviceType: "mobile",

      hasMobiles: false,
      hasTablets: false,
      hasWatch: false,
    };
  },
  computed: {
    ...mapGetters(["getSelectedBrand", "getSelectedBrandId", "allModels"]),
    filteredDevices() {
      return this.modelsList.filter((device) => {
        var totalMatches = 0;
        this.searchTerm.toLowerCase().trim().split(" ");
        for (let i = 0; i < this.searchTerm.length; i++) {
          if (
            device.name.toLowerCase().includes(this.searchTerm[i].toLowerCase())
          ) {
            totalMatches++;
          }
        }
        return totalMatches == this.searchTerm.length;
      });
    },
  },
  methods: {
    ...mapActions(["fetchModels", "setSelectedModelId", "incrementSteps"]),
    filterList() {
      // We now filter the list according to the value of 'deviceType'
      this.modelsList = this.allModels.models.filter((models) => {
        return models.type.toLowerCase() === this.deviceType;
      });
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.fetchModels(this.getSelectedBrandId).then(() => {
      this.modelsList = this.allModels.models;

      // We now filter the list according to the value of 'deviceType'
      this.modelsList = this.allModels.models.filter((models) => {
        return models.type.toLowerCase() === this.deviceType;
      });

      this.hasMobiles = this.allModels.brand.hasMobiles;
      this.hasTablets = this.allModels.brand.hasTablets;
      this.hasWatch = this.allModels.brand.hasWatch;
    });

     this.scrollToTop();
  },
   metaInfo() {
    return {
      title:
        `Repair Phone - ${this.getSelectedBrand} Devices`,
      meta: [
        {
          name:
            `Repair Phone - ${this.getSelectedBrand} Devices`,
          content:
            `Repair Phone - ${this.getSelectedBrand} Devices`,
        },
      ],
    };
  },
};
</script>

<style>
</style>
