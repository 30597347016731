<template>
  <div id="app" class="relative">
    <Navbar ref="navbar" @selectcity="showSnackbar = true" />
    <router-view />
    <Footer />
    <transition name="fade">
      <LoginModal v-if="isLoginModalVisible" />
    </transition>
    <transition name="fade">
      <Snackbar v-if="showSnackbar" @clicked="onClickChild" />
    </transition>
  </div>
</template>
<script>
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import LoginModal from "./components/Stepper/OTP";
import Snackbar from "./components/Snackbar";

// Vuex
import { mapGetters } from "vuex";

export default {
  name: "App",
  data() {
    return {
      showSnackbar: false,
      REPAIR_CITY_KEY: "repair-city-key",
    };
  },
  components: {
    Navbar,
    Footer,
    LoginModal,
    Snackbar,
  },
  methods: {
    onClickChild() {
      this.showSnackbar = false;
      var child = this.$refs.navbar;
      child.checkCity();
    },
  },
  computed: {
    ...mapGetters(["isLoginModalVisible"]),
  },
  created() {
    if (localStorage.getItem(this.REPAIR_CITY_KEY)) {
      this.showSnackbar = false;
    } else {
      this.showSnackbar = true;
    }
  },
};
</script>
