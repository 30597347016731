import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Stepper from "../views/Stepper.vue";
import Privacy from "../views/Misc/Privacy";
import Terms from "../views/Misc/Terms";
import About from "../views/Misc/About";
import Blog from "../views/Misc/Blog";
import TOU from "../views/Misc/TermsOfUse";
import FAQ from "../views/Misc/FAQ";
import NotFound from "../views/404";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/getyourphonerepaired",
    name: "Stepper",
    component: Stepper,
  },
  {
    path: "/privacy-policy",
    name: "Privacy",
    component: Privacy,
  },
  {
    path: "/terms-and-conditions",
    name: "Terms",
    component: Terms,
  },
  {
    path: "/about-us",
    name: "About",
    component: About,
  },
  {
    path: "/blogs",
    name: "Blog",
    component: Blog,
  },
  {
    path: "/terms-of-use",
    name: "TOU",
    component: TOU,
  },
  {
    path: "/faq",
    name: "FAQ",
    component: FAQ,
  },
  {
    path: "/*",
    name: "NotFound",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
