const state = {
  stepCounter: 1,
  selectedBrand: "",
  selectedBrandId: "",
  selectedModelId: "",
  selectedIssues: [],
};
const getters = {
  stepCounter: (state) => {
    return state.stepCounter;
  },

  // Form Data
  getSelectedBrand: (state) => {
    return state.selectedBrand;
  },
  getSelectedBrandId: (state) => {
    return state.selectedBrandId;
  },
  getSelectedModelId: (state) => {
    return state.selectedModelId;
  },
  getSelectedIssues: (state) => {
    return state.selectedIssues;
  },
};
const actions = {
  resetSteps({ commit }) {
    commit("resetCounterStep");
  },
  incrementSteps({ commit }, step) {
    commit("moveToNextStep", step);
  },
  setSelectedBrand({ commit }, brand) {
    commit("setSelectedBrand", brand);
  },
  setSelectedBrandId({ commit }, brandID) {
    commit("setSelectedBrandId", brandID);
  },
  setSelectedModelId({ commit }, modelID) {
    commit("setSelectedModelId", modelID);
  },
  setSelectedIssues({ commit }, issues) {
    commit("setSelectedIssues", issues);
  },
};
const mutations = {
  resetCounterStep: (state) => {
    state.stepCounter = 1;
  },
  moveToNextStep: (state, step) => {
    state.stepCounter = state.stepCounter + step;
  },

  setSelectedBrand: (state, brand) => {
    state.selectedBrand = brand;
  },

  setSelectedBrandId: (state, brandID) => {
    state.selectedBrandId = brandID;
  },

  setSelectedModelId: (state, modelID) => {
    state.selectedModelId = modelID;
  },

  setSelectedIssues: (state, issues) => {
    state.selectedIssues = issues;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
