<template>
  <div class="container-custom mx-auto">
    <div class="text-center">
      <img
        class="mx-auto text-center misc-header-img"
        src="../../assets/images/repair.png"
        alt="Repair Image"
      />
      <h1 class="text-2xl lg:text-3xl xl:text-4xl font-bold text-gray-800">
        Hey there!
      </h1>

      <p
        class="w-full px-4 lg:w-3/5 mx-auto text-lg leading-10 text-gray-700 mt-5 whitespace-pre-line"
      >
        <span
          >Our editors are tirelessly working on writing down detailed blogs so
          that you can effortlessly go through the process of getting your phone
          repaired.</span
        >
      </p>
      <p
        class="w-3/5 mx-auto text-lg leading-10 text-gray-700 mt-5 whitespace-pre-line"
      >
        Until the same is done, go ahead and browse our website to get your
        phone repaired.
      </p>

      <router-link
        to="/"
        class="mt-2 cursor-pointer inline-flex items-center px-6 py-2 font-semibold text-white bg-teal-500 rounded-full hover:bg-teal-600 focus:outline-none focus:shadow-outline active:bg-gray-900"
        >Browse Devices</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "Blog",
  metaInfo() {
    return {
      title: `Repair Phone - Blogs`,
      meta: [
        {
          name: `Repair Phone - Blogs`,
          content: `Repair Phone - Blogs`,
        },
      ],
    };
  },
};
</script>

<style>
.misc-header-img {
  height: 400px;
}

@media screen and (min-width: 320px) {
  .misc-header-img {
    height: 300px;
  }
}

@media (min-width: 640px) {
  .misc-header-img {
    height: 300px;
  }
}

@media (min-width: 768px) {
  .misc-header-img {
    height: 300px;
  }
}

@media (min-width: 1024px) {
  .misc-header-img {
    height: 400px;
  }
}

@media (min-width: 1280px) {
  .misc-header-img {
    height: 400px;
  }
}
</style>
