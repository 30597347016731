var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"my-5 lg:mt-20 px-5"},[_c('div',{staticClass:"flex items-center"},[_c('h2',{staticClass:"font-bold text-gray-800 text-xl lg:text-3xl leading-none"},[_vm._v(" Select Your Device's Issue ")]),_c('button',{staticClass:"ml-auto text-gray-100 font-bold py-2 px-2 lg:px-4 rounded inline-flex items-center transition duration-500",class:_vm.selectedIssue.length <= 0
            ? 'bg-gray-400 cursor-not-allowed'
            : 'bg-green-500 hover:bg-green-600',attrs:{"disabled":_vm.selectedIssue.length <= 0},on:{"click":function($event){_vm.setSelectedIssues(_vm.selectedIssue);
          _vm.incrementSteps(1);}}},[_vm._m(0),_c('svg',{staticClass:"fill-current w-4 h-4 ml-2",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20"}},[_c('path',{attrs:{"d":"M16.172 9l-6.071-6.071 1.414-1.414L20 10l-.707.707-7.778 7.778-1.414-1.414L16.172 11H0V9z"}})])])]),_c('div',{staticClass:"grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 md:gap-6 mt-4 text-center lg:mt-20"},_vm._l((_vm.issues),function(issue,index){return _c('div',{key:index,staticClass:"bg-white border-dashed border-2 hover:border-red-500 rounded-md px-2 lg:px-3 py-4 lg:py-4 transition-all duration-200 transform flex items-center cursor-pointer flex-col lg:flex-row justify-start",class:_vm.selectedIssue.includes(issue.name)
            ? 'border-red-500 bg-red-100'
            : '',on:{"click":function($event){return _vm.selectIssue(issue.name)}}},[_c('img',{staticClass:"h-6 lg:h-10",attrs:{"src":require('../../assets/images/' + issue.image),"alt":""}}),_c('div',{staticClass:"text-left lg:ml-4"},[_c('h2',{staticClass:"font-bold text-gray-800 text-base lg:text-lg mt-2 lg:mt-0 text-center lg:text-left"},[_vm._v(" "+_vm._s(issue.name)+" ")]),_c('p',{staticClass:"text-gray-600 text-sm text-center lg:text-left"},[_vm._v(" "+_vm._s(issue.desc)+" ")])])])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('span',{staticClass:"hidden lg:inline-block"},[_vm._v("Go ")]),_vm._v(" Next")])
}]

export { render, staticRenderFns }