<template>
  <section class="custom-container px-5">
    <h1
      class="font-bold text-gray-800 text-5xl leading-none border-b pb-5 my-5"
    >
      Frequently Asked Questions
    </h1>
    <dl class="px-4 mt-10 text-left md:grid md:grid-cols-2 md:gap-8">
      <div>
        <div>
          <dt class="text-lg leading-6 font-bold text-gray-700">
            1. How many days will it take to repair a phone or a Tablet screen?
          </dt>
          <dd class="mt-2">
            <p class="text-base leading-8 text-gray-600">
              On average, repairs take about 60 minutes or less. This all
              depends on the model of the device being repaired. (only if the
              display has to be changed approximated time)
            </p>
          </dd>
        </div>

        <div>
          <dt class="text-lg leading-6 font-bold text-gray-700 mt-10">
            2. Which type of smart phones you unlock?
          </dt>
          <dd class="mt-2">
            <p class="text-base leading-8 text-gray-600">
              We unlock all types of smart phones in every brand and model if
              the phone belongs to the seller (only) and also depends on the
              model which is not blacklisted by the government or is not
              authorised to be unlocked for example {Emi phone locked}
            </p>
          </dd>
        </div>

        <div>
          <dt class="text-lg leading-6 font-bold text-gray-700 mt-10">
            3. Is it safe to give phone for repair?
          </dt>
          <dd class="mt-2">
            <p class="text-base leading-8 text-gray-600">
              It is for your good and to keep your
              data <b>safe</b> and <b>secure</b>. Things You Must Do Before
              Handing Over Your Phone For Repair: Create a Backup: there is a
              high possibility that your data gets erased while repairing.
              Remove the SIM card: make it your habit to remove the sim card
              before handing your phone to a technician.
            </p>
          </dd>
        </div>

        <div>
          <dt class="text-lg leading-6 font-bold text-gray-700 mt-10">
            4. Do I need to wipe my phone before repair?
          </dt>
          <dd class="mt-2">
            <p class="text-base leading-8 text-gray-600">
              Perform a Factory Reset
              <br /><br />
              If you have sensitive data on your device, then reset your phone.
              This will protect your data from getting stolen and you will be
              carefree. However, remember to back up
              all your data before resetting your phone.
            </p>
          </dd>
        </div>

        <div>
          <dt class="text-lg leading-6 font-bold text-gray-700 mt-10">
            5. Why is Samsung screen repair so expensive?
          </dt>
          <dd class="mt-2">
            <p class="text-base leading-8 text-gray-600">
              Samsung mobile phone screens are notoriously expensive, and often
              the cost of a replacement is nearly as much as a second-hand
              phone. The reason for this is that the manufacturing cost is high-
              most Samsung screens use AMOLED (active matrix organic LED)
              technology and are made by Samsung themselves.
            </p>
          </dd>
        </div>

        <div>
          <dt class="text-lg leading-6 font-bold text-gray-700 mt-10">
            6. What if it takes much time to repair my phone?
          </dt>
          <dd class="mt-2">
            <p class="text-base leading-8 text-gray-600">
              This all depends on the model of the device being repaired. We
              like to be quick with our repairs (while still being professional)
              because we know your time is valuable.
            </p>
          </dd>
        </div>

        <div>
          <dt class="text-lg leading-6 font-bold text-gray-700 mt-10">
            7. What iPads do you repair?
          </dt>
          <dd class="mt-2">
            <p class="text-base leading-8 text-gray-600">
              We can repair all the iPads ranging from the iPad 2 on up to the
              most current iPad Pro models. We keep stock of most models in
              house to make turn around time and repairs quicker, but if we
              don't have the part needed, we can certainly get one ordered in.
            </p>
          </dd>
        </div>
      </div>
    </dl>
  </section>
</template>

<script>
export default {
  name: "FAQ",
  created() {
    this.scrollToTop();
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  metaInfo() {
    return {
      title: `Repair Phone - FAQ`,
      meta: [
        {
          name: `Repair Phone - FAQ`,
          content: `Repair Phone - FAQ`,
        },
      ],
    };
  },
};
</script>

<style>
</style>