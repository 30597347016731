<template>
  <section class="custom-container px-5">
    <h1
      class="font-bold text-gray-800 text-5xl leading-none border-b pb-5 my-5"
    >
      Terms Of Use
    </h1>
    <p>
      Please read these terms of use ("terms of use", "agreement") carefully
      before using [website] repairphone.in (“website”, "service") operated by
      [Repair Phone] ("us", 'we", "our").
    </p>
    <h3 class="mt-10 mb-5 font-bold text-gray-800 text-3xl leading-none">
      Conditions of use
    </h3>
    <p>
      By using this website, you certify that you have read and reviewed this
      Agreement and that you agree to comply with its terms. If you do not want
      to be bound by the terms of this Agreement, you are advised to leave the
      website accordingly. [repairphone] only grants use and access of this
      website, its products, and its services to those who have accepted its
      terms.
    </p>
    <h3 class="mt-10 mb-5 font-bold text-gray-800 text-3xl leading-none">
      Privacy policy
    </h3>
    <p>
      Before you continue using our website, we advise you to read our privacy
      policy [link to privacy policy] regarding our user data collection. It
      will help you better understand our practices.
    </p>
    <h3 class="mt-10 mb-5 font-bold text-gray-800 text-2xl leading-none">
      Age restriction
    </h3>
    <p>
      You must be at least 18 (eighteen) years of age before you can use this
      website. By using this website, you warrant that you are at least 18 years
      of age and you may legally adhere to this Agreement. [reapirphone] assumes
      no responsibility for liabilities related to age misrepresentation.
    </p>
    <h3 class="mt-10 mb-5 font-bold text-gray-800 text-2xl leading-none">
      Intellectual property
    </h3>
    <p>
      You agree that all materials, products, and services provided on this
      website are the property of [repairphone], its affiliates, directors,
      officers, employees, agents, suppliers, or licensors including all
      copyrights, trade secrets, trademarks, patents, and other intellectual
      property. You also agree that you will not reproduce or redistribute the
      [repairphone]’s intellectual property in any way, including electronic,
      digital, or new trademark registrations.
      <br /><br />
      You grant [repairphone] a royalty-free and non-exclusive license to
      display, use, copy, transmit, and broadcast the content you upload and
      publish. For issues regarding intellectual property claims, you should
      contact the company in order to come to an agreement.
    </p>
    <h3 class="mt-10 mb-5 font-bold text-gray-800 text-2xl leading-none">
      User accounts
    </h3>
    <p>
      As a user of this website, you may be asked to register with us and
      provide private information. You are responsible for ensuring the accuracy
      of this information, and you are responsible for maintaining the safety
      and security of your identifying information. You are also responsible for
      all activities that occur under your account or password. <br /><br />
      If you think there are any possible issues regarding the security of your
      account on the website, inform us immediately so we may address it
      accordingly. <br /><br />
      We reserve all rights to terminate accounts, edit or remove content and
      cancel orders in their sole discretion.
    </p>
    <h3 class="mt-10 mb-5 font-bold text-gray-800 text-2xl leading-none">
      Applicable law
    </h3>
    <p>
      By visiting this website, you agree that the laws of the [India], without
      regard to principles of conflict laws, will govern these terms and
      conditions, or any dispute of any sort that might come between
      [repairphone] and you, or its business partners and associates.
    </p>
    <h3 class="mt-10 mb-5 font-bold text-gray-800 text-2xl leading-none">
      Disputes
    </h3>
    <p>
      Any dispute related in any way to your visit to this website or to
      products you purchase from us shall be arbitrated by state or federal
      court [Mumbai] and you consent to exclusive jurisdiction and venue of such
      courts.
    </p>
    <h3 class="mt-10 mb-5 font-bold text-gray-800 text-2xl leading-none">
      Indemnification
    </h3>
    <p>
      You agree to indemnify [repairphone] and its affiliates and hold
      [repairphone] harmless against legal claims and demands that may arise
      from your use or misuse of our services. We reserve the right to select
      our own legal counsel.
    </p>
    <h3 class="mt-10 mb-5 font-bold text-gray-800 text-2xl leading-none">
      Limitation on liability
    </h3>
    <p>
      [repairphone] is not liable for any damages that may occur to you as a
      result of your misuse of our website.
      <br /><br />
      [repairphone] reserves the right to edit, modify, and change this
      Agreement any time. We shall let our users know of these changes through
      electronic mail. This Agreement is an understanding between [repairphone]
      and the user, and this supersedes and replaces all prior agreements
      regarding the use of this website.
    </p>
    <h3 class="mt-10 mb-5 font-bold text-gray-800 text-2xl leading-none">
      Note:- The Services other than as expressly set forth in these terms.
    </h3>
    <p>
      repairphone does not guarantee the suitability, safety or ability of third
      party professionals. It is solely your responsibility to determine if a
      third party professional will meet your needs and expectations. Under no
      circumstances will repairphone participate in disputes between you and a
      third party professional by using the services, you acknowledge that you
      may be exposed to situations involving third party professionals that are
      potentially unsafe, offensive, harmful to minors, or otherwise
      objectionable, and that your use of third party professionals arranged or
      scheduled using the services is at your own risk and judgment. repairphone
      shall not have any liability whatsoever arising from or in any way related
      to your transactions or relationship with third party professionals.
    </p>
  </section>
</template>

<script>
export default {
  name: "Terms of Use",
  created() {
    this.scrollToTop();
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  metaInfo() {
    return {
      title: `Repair Phone - Terms of Use`,
      meta: [
        {
          name: `Repair Phone - Terms of Use`,
          content: `Repair Phone - Terms of Use`,
        },
      ],
    };
  },
};
</script>

<style>
</style>