<template>
  <div class="my-5 lg:mt-20 px-5">
    <div v-if="!success && !failed">
      <h2 class="font-bold text-gray-800 text-2xl lg:text-3xl leading-none">
        Review Details
      </h2>
      <p class="mt-2 text-sm lg:text-base text-gray-600">
        Please make sure that the entered details are correct
      </p>

      <div class="flex flex-col lg:flex-row mt-5 lg:mt-10">
        <div
          class="w-full lg:w-4/6 mr-5 border py-5 lg:py-10 px-5 rounded-md"
          style="height: fit-content"
        >
          <h2
            class="font-bold text-gray-800 text-2xl leading-none border-b-2 pb-5 border-gray-200"
          >
            Device Details
          </h2>
          <div
            class="flex flex-col-reverse lg:flex-column mt-5 items-baseline items-center lg:items-left"
          >
            <div class="w-full">
              <div>
                <div
                  class="align-middle inline-block min-w-full overflow-hidden sm:rounded-lg border-gray-200"
                >
                  <table class="min-w-full">
                    <tbody class="bg-white">
                      <tr>
                        <td
                          class="py-4 whitespace-no-wrap border-b border-gray-200"
                        >
                          <div class="text-md leading-5 text-gray-900">
                            Brand Name:
                          </div>
                        </td>
                        <td
                          class="px-6 py-4 whitespace-no-wrap border-b border-gray-200"
                        >
                          <span
                            class="px-2 py-2 inline-flex text-md leading-5 font-semibold rounded-lg lg:rounded-full bg-gray-100 text-gray-800"
                            >{{ model.name.split(" ")[0] }}</span
                          >
                        </td>
                      </tr>

                      <tr>
                        <td
                          class="py-4 whitespace-no-wrap border-b border-gray-200"
                        >
                          <div class="text-md leading-5 text-gray-900">
                            Model Name:
                          </div>
                        </td>
                        <td
                          class="px-6 py-4 whitespace-no-wrap border-b border-gray-200"
                        >
                          <span
                            class="px-2 py-2 inline-flex text-md leading-5 font-semibold rounded-lg lg:rounded-full bg-gray-100 text-gray-800"
                            >{{ model.name }}</span
                          >
                        </td>
                      </tr>

                      <tr>
                        <td
                          class="py-4 whitespace-no-wrap border-b border-gray-200"
                        >
                          <div class="text-md leading-5 text-gray-900">
                            Device Type:
                          </div>
                        </td>
                        <td
                          class="px-6 py-4 whitespace-no-wrap border-b border-gray-200"
                        >
                          <span
                            class="px-2 py-2 inline-flex text-md leading-5 font-semibold rounded-lg lg:rounded-full bg-gray-100 text-gray-800"
                            >{{ model.type }}</span
                          >
                        </td>
                      </tr>

                      <tr style="vertical-align: text-top">
                        <td class="py-4 whitespace-no-wrap">
                          <div class="text-md leading-5 text-gray-900">
                            Issues Faced:
                          </div>
                        </td>
                        <td class="px-6 py-4 whitespace-no-wrap">
                          <ul>
                            <li
                              v-for="(issue, index) in getSelectedIssues"
                              :key="index"
                            >
                              <span
                                class="px-2 py-2 mb-2 inline-flex text-md leading-5 font-semibold rounded-lg lg:rounded-full bg-gray-100 text-gray-800"
                                >{{ issue }}</span
                              >
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <picture class="flex-1">
              <source
                :srcset="
                  'https://assets.cashmen.in/res/deviceimages/webp/' +
                  model._id +
                  '.webp'
                "
                type="image/webp"
              />
              <source
                :srcset="
                  'https://assets.cashmen.in/res/deviceimages/' +
                  model._id +
                  '.png'
                "
                type="image/jpeg"
              />
              <img
                class="h-32 lg:h-64 ml-auto"
                :src="
                  'https://assets.cashmen.in/res/deviceimages/' +
                  model._id +
                  '.png'
                "
              />
            </picture>
          </div>
        </div>
        <div
          class="mt-5 lg:mt-0 w-full lg:w-2/6 lg:ml-5 border py-10 px-5 rounded-md"
        >
          <h2
            class="font-bold text-gray-800 text-2xl leading-none border-b-2 pb-5 border-gray-200"
          >
            Customer Details
          </h2>
          <div class="flex mt-5 items-center">
            <div class="w-full">
              <div class>
                <div
                  class="align-middle inline-block min-w-full overflow-hidden sm:rounded-lg border-gray-200"
                >
                  <table class="min-w-full">
                    <tbody class="bg-white">
                      <tr>
                        <td
                          class="py-4 whitespace-no-wrap border-b border-gray-200"
                        >
                          <div class="text-md leading-5 text-gray-900">
                            Name:
                          </div>
                        </td>
                        <td
                          class="px-6 py-4 whitespace-no-wrap border-b border-gray-200"
                        >
                          <span
                            class="px-2 py-2 inline-flex text-md leading-5 font-semibold rounded-lg lg:rounded-full bg-gray-100 text-gray-800"
                            >{{ getAppointmentData.firstName }}
                            {{ getAppointmentData.lastName }}</span
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <p class="mt-5 text-gray-800 border-b-2 pb-5 border-gray-200">
            {{ getAppointmentData.address }}, {{ getAppointmentData.city }},
            {{ getAppointmentData.state }}, India,
            {{ getAppointmentData.pincode }}
          </p>
          <div class="flex flex-wrap mb-2 mt-8">
            <label class="inline-flex cursor-pointer" for="t-c">
              <input
                id="t-c"
                type="checkbox"
                class="bg-gray-400 rounded-md text-gray-800 w-10 h-6 lg:w-6 lg:h-6"
                style="transition: all 0.15s ease 0s"
                v-model="checkbox"
              />
              <span
                class="-mt-1 ml-4 text-sm lg:text-md font-semibold text-gray-700"
              >
                I agree to the Repair Phone's
                <router-link
                  target="_blank"
                  to="/terms-and-conditions"
                  class="text-red-500 mr-1"
                  >Terms & Condition</router-link
                >and
                <router-link
                  target="_blank"
                  to="/privacy-policy"
                  class="text-red-500"
                  >Privacy Policy</router-link
                >
              </span>
            </label>
          </div>

          <button
            :disabled="checkbox === false"
            @click="generateAppointment"
            :class="
              !checkbox
                ? 'bg-gray-400 hover:bg-gray-400 cursor-not-allowed'
                : 'bg-gray-800 hover:bg-gray-700 cursor-pointer'
            "
            class="w-full ml-auto mt-8 text-gray-100 font-bold py-3 px-4 lg:px-8 rounded text-base lg:text-xl inline-flex items-center transition duration-500"
          >
            <span>Schedule Appointment</span>
            <svg
              class="fill-current w-4 h-4 ml-auto"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M16.172 9l-6.071-6.071 1.414-1.414L20 10l-.707.707-7.778 7.778-1.414-1.414L16.172 11H0V9z"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
    <Success v-if="success" />
    <Failed v-if="failed" />
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import Success from "../Successful";
import Failed from "../Failed";

export default {
  name: "Step5",
  data() {
    return {
      model: [],
      modelName: "",
      checkbox: false,
      success: false,
      failed: false,
    };
  },
  components: {
    Success,
    Failed,
  },
  computed: {
    ...mapGetters([
      "allModels",
      "getSelectedModelId",
      "getSelectedBrandId",
      "getAppointmentData",
      "getSelectedIssues",
    ]),
  },
  methods: {
    ...mapActions(["agreeToAgreements", "incrementSteps"]),
    generateAppointment() {
      if (this.checkbox) {
        this.agreeToAgreements();
        // Now generate an appointment

        let appointmentData = {
          // Client Data
          firstName: this.getAppointmentData.firstName,
          lastName: this.getAppointmentData.lastName,
          phoneNumber: this.getAppointmentData.phoneNumber,
          emailAddress: this.getAppointmentData.emailAddress,
          address: this.getAppointmentData.address,
          city: this.getAppointmentData.city,
          state: this.getAppointmentData.state,
          pincode: this.getAppointmentData.pincode,
          dateSlot: this.getAppointmentData.dateSlot,

          // Phone Data
          model: this.model.name,
          brand: this.model.name.split(" ")[0],
          modelId: this.model._id,
          brandId: this.getSelectedBrandId,
          modelType: this.model.type,

          // Issues
          issues: this.getSelectedIssues,

          // agreement
          agreement: true,
        };

        // Sent Data to server
        let url = "https://assets.repairphone.in/repair/orders/submitorder.php";
        axios
          .post(url, appointmentData)
          .then(() => {
            this.success = true;
            this.failed = false;
            this.incrementSteps(1);
          })
          .catch((err) => {
            console.log("Error occured:", err);
            this.success = false;
            this.failed = true;
          });
      }
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  metaInfo() {
    return {
      title: `Repair Phone - Review Details`,
      meta: [
        {
          name: `Repair Phone - Review Details`,
          content: `Repair Phone - Review Details`,
        },
      ],
    };
  },
  created() {
    this.scrollToTop();
    this.allModels.models.forEach((device) => {
      if (device._id === this.getSelectedModelId) {
        this.model = device;
      }
    });
  },
};
</script>

<style>
</style>
