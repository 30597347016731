<template>
  <header class="custom-container flex items-center mt-10 px-5">
    <div class="left flex-1 text-center lg:text-left">
      <h2
        class="left-top font-bold text-gray-800 text-center lg:text-left text-4xl lg:text-6xl leading-none"
      >
        Repair Your <span class="accent-text-color">Mobile Phone or Tablet</span>
        Instantly
      </h2>
      <p
        class="left-desc text-gray-700 text-xl lg:text-2xl max-w-2xl mt-5 lg:mt-10 text-center lg:text-left mx-auto lg:mx-0"
      >
        Get your <b>Faulty</b> phone or tablet diagnosed and repaired with our
        <b>Trusted</b> door to door service in your city from the comfort of your home
      </p>
      <router-link
        to="getyourphonerepaired"
        class="accent-color text-white font-semibold py-3 px-8 rounded inline-flex items-center text-2xl mt-10 hover:shadow-lg transition duration-500 main-button"
      >
        <svg
          class="fill-current w-6 h-6 mr-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path
            d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-7.59V4h2v5.59l3.95 3.95-1.41 1.41L9 10.41z"
          />
        </svg>
        <span>Book Now</span>
      </router-link>
    </div>
    <div class="right flex-1 relative hidden lg:block overflow-hidden">
      <img
        src="../../assets/images/hero.png"
        alt
      />
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
};
</script>

<style>
</style>
