<template>
  <div>
    <div class="my-5 lg:mt-20 px-5">
      <div class="flex items-center">
        <h2 class="font-bold text-gray-800 text-xl lg:text-3xl leading-none">
          Select Your Device's Issue
        </h2>
        <button
          :disabled="selectedIssue.length <= 0"
          :class="
            selectedIssue.length <= 0
              ? 'bg-gray-400 cursor-not-allowed'
              : 'bg-green-500 hover:bg-green-600'
          "
          @click="
            setSelectedIssues(selectedIssue);
            incrementSteps(1);
          "
          class="ml-auto text-gray-100 font-bold py-2 px-2 lg:px-4 rounded inline-flex items-center transition duration-500"
        >
          <span><span class="hidden lg:inline-block">Go </span> Next</span>
          <svg
            class="fill-current w-4 h-4 ml-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              d="M16.172 9l-6.071-6.071 1.414-1.414L20 10l-.707.707-7.778 7.778-1.414-1.414L16.172 11H0V9z"
            />
          </svg>
        </button>
      </div>

      <div
        class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 md:gap-6 mt-4 text-center lg:mt-20"
      >
        <div
          @click="selectIssue(issue.name)"
          v-for="(issue, index) in issues"
          :key="index"
          class="bg-white border-dashed border-2 hover:border-red-500 rounded-md px-2 lg:px-3 py-4 lg:py-4 transition-all duration-200 transform flex items-center cursor-pointer flex-col lg:flex-row justify-start"
          :class="
            selectedIssue.includes(issue.name)
              ? 'border-red-500 bg-red-100'
              : ''
          "
        >
          <img
            class="h-6 lg:h-10"
            :src="require('../../assets/images/' + issue.image)"
            alt
          />
          <div class="text-left lg:ml-4">
            <h2
              class="font-bold text-gray-800 text-base lg:text-lg mt-2 lg:mt-0 text-center lg:text-left"
            >
              {{ issue.name }}
            </h2>
            <p class="text-gray-600 text-sm text-center lg:text-left">
              {{ issue.desc }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Step3",
  data() {
    return {
      issues: [
        {
          name: "Back Camera",
          desc: "Blurry pictures, cracked lens, dusty lens, etc",
          image: "issue-back-camera.png",
        },
        {
          name: "Fingerprint / Fingertouch",
          desc: "Fingerprint not accepted, scan not working, etc",
          image: "issue-fingerprint.png",
        },
        {
          name: "GPS",
          desc: "Incorrect location, caliberation issue, etc",
          image: "issue-gps.png",
        },
        {
          name: "Other Buttons (Keys)",
          desc: "Any physicall button not working.",
          image: "issue-buttons.png",
        },
        {
          name: "All Speakers",
          desc: "No sound, muffled sound, low sound, etc",
          image: "issue-speaker.png",
        },
        {
          name: "Face Scanner",
          desc: "Face detection not working, very slow, etc",
          image: "issue-scanner.png",
        },
        {
          name: "Battery Issue",
          desc: "Rapid battery drain, leaking battery, etc",
          image: "issue-battery.png",
        },
        {
          name: "Proximity Sensor",
          desc: "Incorrect readings, etc",
          image: "issue-proximity.png",
        },
        {
          name: "Charging Problem",
          desc: "Unable to charge, charger not working, etc",
          image: "issue-charging.png",
        },
        {
          name: "Front Camera",
          desc: "Blurry pictures, cracked lens, dusty lens, etc",
          image: "issue-front-camera.png",
        },
        {
          name: "Screen",
          desc: "Crack on screen, lines on screen, etc",
          image: "issue-screen.png",
        },
        {
          name: "Vibrator (Diagnosis)",
          desc: "Not vibration during calls, very low vibration, etc",
          image: "issue-vibration.png",
        },
        {
          name: "WiFi (Diagnosis)",
          desc: "Unable to connect to WiFi, no internet, etc",
          image: "issue-wifi.png",
        },
        {
          name: "Software (Diagnosis)",
          desc:
            "Frequent hang, glitchy software, flashing, booting, restart, etc",
          image: "issue-software.png",
        },
        {
          name: "Network (Diagnosis)",
          desc: "No internet, unable to make calls, etc",
          image: "issue-network.png",
        },
        {
          name: "On/Off Issue (Diagnosis)",
          desc: "Unable to switch On or Off the device",
          image: "issue-power.png",
        },
        {
          name: "Liquid/Water Damage (Diagnosis)",
          desc: "Phone dropped in any liquid substance.",
          image: "issue-liquid.png",
        },
        {
          name: "Earphone/Headphone Port",
          desc: "One side not working, low sound, etc",
          image: "issue-earphone.png",
        },
        {
          name: "Phone Unlocking",
          desc: "Pattern lock, forget password, ID lock, etc",
          image: "phone-lock.svg",
        },
        {
          name: "Microphone Issue",
          desc: "Low volume on other end of the call",
          image: "microphone.svg",
        },
        {
          name: "Bluetooth Issue",
          desc: "Bluetooth devices connectivity/ visibility issue",
          image: "bluetooth.svg",
        },
        {
          name: "Audio Receiver Issue",
          desc: "Unable to hear other person's voice on call",
          image: "speaker.svg",
        },
        {
          name: "Silent Button/ Slider",
          desc: "Unable to put the phone on silent mode",
          image: "slider.svg",
        },
        {
          name: "Power Button",
          desc: "Unable to restart/ switch on/ switch off the device",
          image: "power.svg",
        },
        {
          name: "Other",
          desc: "Issues that are not listed above",
          image: "other.svg",
        },
      ],
      selectedIssue: [],
    };
  },

  methods: {
    ...mapActions(["setSelectedIssues", "incrementSteps"]),
    selectIssue(issue) {
      if (this.selectedIssue.includes(issue)) {
        this.selectedIssue = this.selectedIssue.filter((element) => {
          return element !== issue;
        });
      } else {
        this.selectedIssue.push(issue);
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  metaInfo() {
    return {
      title: `Repair Phone - Device Issues`,
      meta: [
        {
          name: `Repair Phone - Device Issues`,
          content: `Repair Phone - Device Issues`,
        },
      ],
    };
  },
  created() {
    this.scrollToTop();
  },
};
</script>

<style>
</style>
