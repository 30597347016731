<template>
  <main class="custom-container">
    <Steps />
    <Step1 v-if="stepCounter == 1" />
    <Step2 v-if="stepCounter == 2" />
    <Step3 v-if="stepCounter == 3" />
    <Step4 v-if="stepCounter == 4" />
    <Step5 v-if="stepCounter >= 5" />
  </main>
</template>

<script>
import Steps from "../components/Stepper/Steps";
import Step1 from "../components/Stepper/Step1";
import Step2 from "../components/Stepper/Step2";
import Step3 from "../components/Stepper/Step3";
import Step4 from "../components/Stepper/Step4";
import Step5 from "../components/Stepper/Step5";

// Vuex
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Stepper",
  components: {
    Steps,
    Step1,
    Step2,
    Step3,
    Step4,
    Step5
  },
  computed: {
    ...mapGetters(["stepCounter", "getSelectedBrand"])
  },
  methods: {
    ...mapActions(["disagreeToAgreements"]),
  },
  created() {
    this.disagreeToAgreements();
  }
};
</script>

<style>
</style>
