<template>
  <div class="container-custom mx-auto">
    <nav
      class="text-black font-bold my-4 px-4 md:px-2 xl:px-0"
      aria-label="Breadcrumb"
    >
      <ol class="list-none p-0 inline-flex">
        <li class="flex items-center">
          <router-link to="/" class="text-gray-500">Home</router-link>
          <svg
            class="fill-current w-3 h-3 mx-3"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
          >
            <path
              d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
            />
          </svg>
        </li>
        <li>
          <a href="#" class="text-gray-600 capitalize" aria-current="page"
            >Order Failed</a
          >
        </li>
      </ol>
    </nav>

    <div class="text-center">
      <img
        class="mx-auto text-center misc-header-img"
        src="../assets/images/Failed.png"
        alt="Repair Image"
      />
      <h1 class="text-2xl lg:text-3xl xl:text-4xl font-bold text-gray-800">
        Oh no! Order Failed
      </h1>

      <p
        class="w-full px-4 lg:w-3/5 mx-auto text-lg leading-8 text-gray-700 mt-5 whitespace-pre-line"
      >
        <span
          >Unfortunately, due to technical difficulty we were not able to
          process your request at the moment.</span
        >
      </p>
      <p
        class="w-3/5 mx-auto text-lg leading-10 text-gray-700 mt-5 whitespace-pre-line"
      >
        Kindly, reach out to us on "support@repairphone.in" and we shall help
        you with your query at the earliest.
      </p>

      <router-link
        to="/"
        type="button"
        class="mt-5 inline-flex items-center px-8 py-4 border border-gray-300 text-lg leading-5 font-medium rounded-md text-white bg-gray-800 hover:bg-gray-700 focus:outline-none focus:shadow-outline focus:border-blue-300 transition duration-250 ease-in-out"
      >
        <svg
          class="-ml-1 mr-2 h-6 w-6"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
        </svg>
        Try again?
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Failed",
  created() {
    this.scrollToTop();
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style>
.misc-header-img {
  height: 20rem;
}

@media (min-width: 1024px) {
  .misc-header-img {
    height: 30rem;
  }
}
</style>
