<template>
  <section class="mb-20 lg:mb-40text-gray-700 body-font relative px-5">
    <img
      src="../../assets/images/particles-yellow-7.png"
      class="absolute floating-particles-5 hidden lg:block"
      v-rellax="{ speed: 1 }"
      alt
    />
    <div class="text-center">
      <h2 class="font-bold text-gray-800 text-3xl md:text-4xl leading-none">
        What does our users say?
      </h2>
      <p class="mt-5 lg:text-xl text-gray-700 max-w-3xl mx-auto">
        We at Repair Phone believe in providing the best of service & experience
        to all our users
      </p>
    </div>
    <div class="container px-5 py-4 lg:py-12 mx-auto">
      <div class="flex flex-wrap -m-4">
        <div class="lg:w-1/3 lg:mb-0 mb-6 p-4">
          <div class="h-full text-center">
            <img
              alt="testimonial"
              class="w-20 h-20 mb-8 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100"
              src="../../assets/images/user1.png"
            />
            <p class="leading-relaxed text-lg">
              Very Good Service from Repair Phone given by Mudassir got better at repairing phone
              from other website. I will defintely recommend Repair Phone for sure.
            </p>
            <span
              class="inline-block h-1 w-10 rounded bg-yellow-500 mt-6 mb-4"
            ></span>
            <h2
              class="text-gray-900 font-medium title-font tracking-wider text-sm font-bold"
            >
              Faizan Basit
            </h2>
            <p class="text-gray-500">Pune</p>
          </div>
        </div>
        <div class="lg:w-1/3 lg:mb-0 mb-6 p-4">
          <div class="h-full text-center">
            <img
              alt="testimonial"
              class="w-20 h-20 mb-8 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100"
              src="../../assets/images/user2.png"
            />
            <p class="leading-relaxed text-lg">
              Very quick and professional service. Picked up my Oneplus 6 within
              2 hours of placing request. The executive, was very professional
              and courteous. Will recommend their service.
            </p>
            <span
              class="inline-block h-1 w-10 rounded bg-yellow-500 mt-6 mb-4"
            ></span>
            <h2
              class="text-gray-900 font-medium title-font tracking-wider text-sm font-bold"
            >
              Sulemaan Ghatkai
            </h2>
            <p class="text-gray-500">Mumbai</p>
          </div>
        </div>
        <div class="lg:w-1/3 lg:mb-0 p-4">
          <div class="h-full text-center">
            <img
              alt="testimonial"
              class="w-20 h-20 mb-8 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100"
              src="../../assets/images/user3.png"
            />
            <p class="leading-relaxed text-lg">
              I placed the order got the call in an hour from the technician and repairing was done in front of me m happy with the services recommended
            </p>
            <span
              class="inline-block h-1 w-10 rounded bg-yellow-500 mt-6 mb-4"
            ></span>
            <h2
              class="text-gray-900 font-medium title-font tracking-wider text-sm font-bold"
            >
              Shalaka Gore
            </h2>
            <p class="text-gray-500">Mumbai</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Section4",
};
</script>

<style>
</style>
