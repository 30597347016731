<template>
  <section class="custom-container px-5">
    <div class="text-center">
      <img
        class="mx-auto text-center misc-header-img"
        src="../../assets/images/about-us.png"
        alt="About us image"
      />
      <h1 class="text-2xl lg:text-3xl xl:text-4xl font-bold text-gray-800">
        What is Repair Phone?
      </h1>

      <p
        class="px-4 text-lg leading-10 text-gray-700 mt-5 whitespace-pre-line text-left"
      >
        <span>
          Welcome to Repair Phone, we are a revolutionary platform where we fix
          your mobile & tablet issue with your convenience from the comfort of
          your doorstep. Get Instant Repair at Home or Office making your mobile
          repairs service process as smooth as your purchase of new mobile. We
          Assure Hassle-Free Experience with our Free Device Pick-Up and Drop
          service from door to door in your city, we love to repair your phone
          right in front of you so that your data safety is not compromised and
          save your travelling time and cost. However, if it'll take time, we
          will further diagnose it in our well-equipped lab using certified
          tools and quality parts with our Certified experienced technicians to
          fix your issue Fast & in a reliable manner.
          <br />
          <br />⚡ Doorstep pickup at your home/ office <br />⚡ Diagonsed
          and repaired right in front of you <br />⚡ Once your mobile is
          repaired we sent it to our mobile experts for quality check. <br />⚡
          Free Device Pick-Up <br />⚡ Your device will be checked by our highly
          skilled technicians. <br />
          <br />Our customer centric approach makes us stand apart from the
          league. We believe in nurturing our association with every client
          through a customized methodology and best in class services.
        </span>
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "About Us",
  created() {
    this.scrollToTop();
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  metaInfo() {
    return {
      title: `Repair Phone - About Us`,
      meta: [
        {
          name: `Repair Phone - About Us`,
          content: `Repair Phone - About Us`,
        },
      ],
    };
  },
};
</script>

<style>
</style>