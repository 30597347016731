<template>
  <section class="mb-20 lg:mb-40 text-gray-700 body-font relative px-5">
    <img
      src="../../assets/images/particles-yellow-5.png"
      class="absolute floating-particles-3 hidden lg:block"
      v-rellax="{ speed: 2 }"
      alt
    />
    <img
      src="../../assets/images/particles-yellow-6.png"
      class="absolute floating-particles-4 hidden lg:block"
      v-rellax="{ speed: 1 }"
      alt
    />
    <div class="text-center">
      <h2 class="font-bold text-gray-800 text-3xl md:text-4xl leading-none">
        What Do We Provide?
      </h2>
      <p class="mt-5 lg:text-xl text-gray-700 max-w-4xl mx-auto">
        Repair Phone is a revolutionary service platform where we fix your
        mobile phone issue with your convenience from the comfort of your home.
      </p>
    </div>

    <div class="container px-5 py-4 lg:py-12 mx-auto">
      <div
        class="flex items-center lg:w-3/5 mx-auto border-b pb-10 mb-10 border-gray-200 sm:flex-row flex-col"
      >
        <div
          class="sm:w-32 sm:h-32 h-20 w-20 sm:mr-10 inline-flex items-center justify-center rounded-full bg-yellow-100 text-yellow-500 flex-shrink-0"
        >
          <svg
            fill="currentcolor"
            class="sm:w-16 sm:h-16 w-10 h-10"
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 0 24 24"
            width="24"
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path
              d="M11.92 8.28c.24-1.4-.16-2.89-1.24-3.96-.94-.95-2.2-1.39-3.44-1.32l3.09 3.09-4.24 4.24L3 7.24c-.07 1.24.37 2.49 1.31 3.44 1.04 1.04 2.47 1.45 3.83 1.25.71-.1 1.4-.38 2-.82l9.46 9.46.88-.88-9.45-9.45c.47-.6.77-1.26.89-1.96z"
              opacity=".3"
            />
            <path
              d="M22.61 18.97L13.54 9.9c.93-2.34.45-5.1-1.44-7C9.8.6 6.22.39 3.67 2.25L7.5 6.08 6.08 7.5 2.25 3.67C.39 6.21.6 9.79 2.9 12.09c1.86 1.86 4.57 2.35 6.89 1.48l9.11 9.11c.39.39 1.02.39 1.41 0l2.3-2.3c.4-.38.4-1.02 0-1.41zm-3 1.6l-9.46-9.46c-.61.45-1.29.72-2 .82-1.36.2-2.79-.21-3.83-1.25-.95-.94-1.39-2.2-1.32-3.44l3.09 3.09 4.24-4.24L7.24 3c1.24-.07 2.49.37 3.44 1.31 1.08 1.08 1.49 2.57 1.24 3.96-.12.7-.42 1.36-.88 1.95l9.45 9.45-.88.9z"
            />
          </svg>
        </div>
        <div class="flex-grow sm:text-left text-center mt-6 sm:mt-0">
          <h2
            class="font-bold text-gray-700 text-2xl title-font font-medium mb-2"
          >
            Premium Repair
          </h2>
          <p class="leading-relaxed text-base">
            We make sure that we use authentic and quality parts while repairing
            your mobile phone or tablet so that you don't have to bother about
            it ever again
          </p>
        </div>
      </div>
      <div
        class="flex items-center lg:w-3/5 mx-auto border-b pb-10 mb-10 border-gray-200 sm:flex-row flex-col"
      >
        <div class="flex-grow sm:text-left text-center mt-6 sm:mt-0">
          <h2
            class="font-bold text-gray-700 text-2xl title-font font-medium mb-2"
          >
            Instant Doorstep Repair
          </h2>
          <p class="leading-relaxed text-base">
            Repair your Mobile, iPad or Tablet from the comfort of your home and
            office. Our Certified Technician will come to your doorstep to work
            on your device and repair it.
          </p>
        </div>
        <div
          class="sm:w-32 sm:order-none order-first sm:h-32 h-20 w-20 sm:ml-10 inline-flex items-center justify-center rounded-full bg-yellow-100 text-yellow-500 flex-shrink-0"
        >
          <svg
            fill="currentcolor"
            class="sm:w-16 sm:h-16 w-10 h-10"
            xmlns="http://www.w3.org/2000/svg"
            enable-background="new 0 0 24 24"
            height="24"
            viewBox="0 0 24 24"
            width="24"
          >
            <g><path d="M0,0h24v24H0V0z" fill="none" /></g>
            <g>
              <g>
                <path
                  d="M17,11h2v2h-2v2h2v2h-2v2h4V5h-9v1.4l5,3.57V11z M17,7h2v2h-2V7z"
                  opacity=".3"
                />
                <polygon
                  points="10,3 10,4.97 10.96,5.66 12,6.4 12,5 21,5 21,19 17,19 17,21 23,21 23,3"
                />
                <polygon
                  opacity=".3"
                  points="3,12 3,19 5,19 5,14 11,14 11,19 13,19 13,12 8,8.5"
                />
                <rect height="2" width="2" x="17" y="7" />
                <rect height="2" width="2" x="17" y="11" />
                <rect height="2" width="2" x="17" y="15" />
                <path
                  d="M1,11v10h6v-5h2v5h6V11L8,6L1,11z M13,19h-2v-5H5v5H3v-7l5-3.5l5,3.5V19z"
                />
              </g>
            </g>
          </svg>
        </div>
      </div>
      <div class="flex items-center lg:w-3/5 mx-auto sm:flex-row flex-col">
        <div
          class="sm:w-32 sm:h-32 h-20 w-20 sm:mr-10 inline-flex items-center justify-center rounded-full bg-yellow-100 text-yellow-500 flex-shrink-0"
        >
          <svg
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            class="sm:w-16 sm:h-16 w-10 h-10"
            viewBox="0 0 24 24"
          >
            <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2" />
            <circle cx="12" cy="7" r="4" />
          </svg>
        </div>
        <div class="flex-grow sm:text-left text-center mt-6 sm:mt-0">
          <h2
            class="font-bold text-gray-700 text-2xl title-font font-medium mb-2"
          >
            Certified Technicians
          </h2>
          <p class="leading-relaxed text-base">
            Our highly Trained and Experienced Professionals make sure your
            device is repaired back to its original condition. Book a free
            technician visit according to your convenience for a free inspection
            of your device.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Section3",
};
</script>

<style>
</style>
